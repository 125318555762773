import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoadingOverlay = ({text}) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.content}>
        <ClipLoader color="#ffffff" />
        <p style={styles.text}>{text}</p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    marginTop: 20,
    color: '#ffffff',
    fontSize: '20px',
  },
};

export default LoadingOverlay;