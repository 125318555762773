import nameLogo from '../assets/art/name_3.svg'

const Footer = () => {
  return(
    <div className='pane-wrapper' style={{height:"350px", backgroundColor:"#1c1c1c",color:"#fff"}} >
      <img src={nameLogo} style={{height:"15em"}} alt="logo"/>
      <p style={{margin: '10px 0'}}>© 2024 Volt Applications LLC. All rights reserved.</p>
      <div style={{display: 'flex', gap: '10px'}}>
        {/* <a href="/privacy-policy" style={{color: '#fff', textDecoration: 'none'}}>Privacy Policy</a> */}
        {/* <a href="/terms-of-service" style={{color: '#fff', textDecoration: 'none'}}>Terms of Service</a> */}
      </div>
    </div>
  )
}

export default Footer