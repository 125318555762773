import {useRef} from 'react'

const BookmarkPopup = ({pageNum, pageCount,addBookmarkToList,setPageAddingState}) => {
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// STATES & REFERENCES
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const formRef = useRef()

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// FUNCTIONS & METHODS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const compileForm = () => {
  console.log(formRef.current[0].value)
  console.log(formRef.current[1].value)
  const bookMarkData = {
    pageNum: formRef.current[0].value,
    name: formRef.current[1].value,
  }
  addBookmarkToList(bookMarkData)
}
  
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// XML RETURNS AND FUNCTIONAL COMPONENTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
return(
  <div style={{
    position: 'fixed',
    top: 0, bottom: 0, left: 0, right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <form ref={formRef} style={{
      backgroundColor: '#292929',
      padding: '1em',
      width: "10em",
      display: "flex",
      flexDirection: "column",
      fontSize: "1.5em",
      alignItems: "center",
      borderRadius: "1em",
      filter: `drop-shadow(0px 0px 10px black)`,
      color: "white"
    }}>
      <div style={{fontSize: "1.2em", margin: "0.2em"}}>Add a bookmark.</div>
      <div style={{marginTop: "0.5em"}}></div>
      <div>Choose a page:</div>
      <select style={{fontSize: '1em'}}>
        <option value={pageNum}>{pageNum}</option>
        {Array.from({length: pageCount}, (_,i)=> i +1)
        .filter(page => page != pageNum)
        .map(page => <option value={page}>{page}</option>)}
      </select>
      <div style={{marginTop: "0.5em"}}></div>
      <div>Give it a title:</div>
      <input style={{fontSize: "0.8em"}}></input>
      <div style={{marginTop: "0.5em"}}></div>
      <div>
        <button style={{fontSize: '0.7em'}} type="button" onClick={compileForm}>Save</button>
        <button style={{fontSize: '0.7em'}} type="button" onClick={()=>setPageAddingState(false)}>Cancel</button>
      </div>
    </form>
  </div>
)
}

export default BookmarkPopup