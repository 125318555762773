import {BsFillTrash3Fill,
  BsInfoCircleFill,
  BsPlusSquareFill,
  BsWrench,
  BsEyeSlashFill,
  BsEyeFill,
  BsXCircleFill,
  BsFillPencilFill,
  BsArrowUpRightSquareFill,
  BsCircleSquare,
  BsArrowsMove,
  BsCircleHalf,
  BsSquareHalf,
  BsClipboardCheckFill,
  BsGrid3X3,
  BsArrowLeftRight,
  BsArrowLeftSquareFill,
  BsArrowRightSquareFill,
  
} from 'react-icons/bs'
import {
  BsFillDoorOpenFill,
  BsSafe2Fill,
  BsBoxFill,
  BsBox2Fill,
  BsDropbox,
  BsFillDropletFill,
  BsDropletHalf,
  BsFillHouseFill,
  BsFillLampFill,
  BsModemFill,
  BsPcDisplay,
  BsMotherboardFill,
  BsProjectorFill,
  BsFillLightbulbFill,
  BsFillLightningChargeFill,
  BsFillLightningFill,
  BsBatteryCharging,
  BsFillMagnetFill,
  BsFillPlugFill,
  BsEthernet,
  BsEvStationFill,
  BsOutlet,
  BsFileLock2Fill,
  BsFan,
  BsGearWideConnected,
  BsGearWide,
  BsNutFill,
  BsHammer,
  BsTools,
  BsFillWrenchAdjustableCircleFill,
  BsConeStriped,
  BsTrash3Fill,
  BsFillTagFill,
  BsPostageFill,
  BsPostage,
  BsPostageHeartFill,
  BsHourglassSplit,
  BsFillTreeFill,
  BsTree,
  BsFillPinFill,
  BsCameraFill,
  BsCoin,
  BsRadioactive,
  BsSuitSpadeFill,
  BsSuitClubFill,
  BsSuitHeartFill,
  BsSuitDiamondFill,
  BsFire,
  BsGiftFill,
  BsPiggyBankFill,
} from 'react-icons/bs'
import {
  MdKey,
  MdVpnKey,
  MdEco,
  MdEnergySavingsLeaf,
  MdStars,
  MdStarRate,
  MdGesture,
  MdWaves,
  MdAir,
  MdCable,
  MdPriceCheck,
  MdWidgets,
  MdFormatPaint,
  MdBrush,
  MdGridOn,
  MdGrain,
  MdReceiptLong,
  MdDesignServices,
  MdLocalLaundryService,
  MdLocalHotel,
  MdLocalFlorist,
  MdLocalAtm,
  MdPlumbing,
  MdAcUnit,
  MdMicrowave,
  MdLight,
  MdHive,
  MdRecycling,

} from 'react-icons/md'
import {GrSelect} from 'react-icons/gr'
import {FaHighlighter} from 'react-icons/fa'
import {RiCompasses2Fill} from 'react-icons/ri'
import {RxDimensions} from 'react-icons/rx'
import {AiOutlineColumnWidth,AiOutlineEdit} from 'react-icons/ai'
import circleArea from './circle-area.svg'
import polyArea from './poly-area.svg'
import polyArea2 from './poly-area2.svg'
import tapeMeasure from './tape-measure.svg'
import lineMeasure from './line-measure.svg'
import {BiSelection} from 'react-icons/bi'
import ProductTile from './ProductTile'
import {useState,useEffect,useRef} from 'react'
const OptionsBar = ({
  chosenTool,
  setChosenToolOption,
  chosenToolOption,
  pinListOpen,
  setPinListOpen,
  setDrawingOptions,
  drawingOptions,
  setPinVis,
  pinVis,
  setMarkVis,
  markVis,
  chosenPalette,
  setChosenPalette,
  setPageConstants,
  pageConstants,
  chosenItemsList,
  setChosenItemsList,
  chosenProduct,
  setChosenProduct,
  productAddingState,
  setProductAddingState,
  iconRegistry,
  iconNames,
  deleteProductFromList,
  countState,
  productVis,
  setProductVis,
  setProductEditState,
  productEditState,
  forceUpdate,
  setMeasureVis,
  measureVis,
  selectedStampIndexes,
  multiplyStampsFunc,
  multiplyRef
}) => {

  const [productCollapse,setProductCollapse] = useState(false)
  const [removalState,setRemovalState] = useState(false)

const scaleRef=useRef()


switch(chosenTool) {
  case "noTool": return
  case "pinsTool":
    return(
      <div style={{
        height: "26em",
      }}
        className="options-bar">
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption("");
            setTimeout(() => setChosenToolOption(chosenToolOption === "addPin" ? "" : "addPin"), 5);
            setPinListOpen(false)
            setPinVis(true)
            }}>
            <div className='options-button-icon'><BsPlusSquareFill /></div>
            <div className='options-button-text'>ADD PIN</div>
          </div>
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption === "editPin" ? "" : "editPin")
            setPinListOpen(false)
            setPinVis(true)
            }}>
            <div className='options-button-icon'><BsWrench /></div>
            <div className='options-button-text'>EDIT PIN</div>
          </div>
          <div className="options-button-box" onClick={()=>{
              setChosenToolOption(chosenToolOption === "movePin" ? "" : "movePin")
              setPinVis(true)
              }}>
              <div className='options-button-icon'><BsArrowsMove /></div>
              <div className='options-button-text'>MOVE PINS</div>
            </div>
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption === "trashPin" ? "" : "trashPin")
            setPinListOpen(false)
            setPinVis(true)
            }}>
            <div className='options-button-icon'><BsFillTrash3Fill /></div>
            <div className='options-button-text'>DELETE PIN</div>
          </div>
          {pinListOpen == false && (
            <div className="options-button-box" onClick={()=>{
              setChosenToolOption(chosenToolOption === "listPin" ? "" : "listPin")
              setPinListOpen(true)
              setPinVis(true)
              }}>
              <div className='options-button-icon'><BsInfoCircleFill /></div>
              <div className='options-button-text'>LIST PINS</div>
            </div>
            
          )}
          {pinListOpen == true && (
            <div className="options-button-box" onClick={()=>{
              setChosenToolOption(chosenToolOption === "listPin" ? "" : "listPin")
              setPinListOpen(false)
              setPinVis(true)
              }}>
              <div className='options-button-icon' style={{color: "#d80000"}}><BsXCircleFill /></div>
              <div className='options-button-text'>CLOSE LIST</div>
            </div>
          )}
          
          {pinVis == true && (
            <div className="options-button-box" onClick={()=>{
              setChosenToolOption("hidePin")
              setPinVis(false)
              setPinListOpen(false)
              }}>
              <div className='options-button-icon'><BsEyeSlashFill /></div>
              <div className='options-button-text'>HIDE PINS</div>
            </div>
          )}
          {pinVis == false && (
            <div className="options-button-box" onClick={()=>{
              setChosenToolOption("showPin")
              setPinVis(true)
              setPinListOpen(false)
              }}>
              <div className='options-button-icon'><BsEyeFill /></div>
              <div className='options-button-text'>SHOW PINS</div>
            </div>
          )}
          
        </div>
        
    )
  case "markupTool":
  return(
      <div style={{height: "30em"}}
        className="options-bar">
            {(chosenToolOption == "penMarkup" ||
              chosenToolOption == "highlightMarkup" ||
              chosenToolOption == "arrowMarkup" ||
              chosenToolOption == "rectangleMarkup" ||
              chosenToolOption == "circleMarkup") && (
                <div className='options-bar mini'>
      <div className='pen-size-option noClick'>SIZE</div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, size: 4})
        setChosenPalette((prev)=>({...prev,size: "S"}))}}
        style={{border: "2px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      >S</div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, size: 8})
        setChosenPalette((prev)=>({...prev,size: "M"}))}}
        style={{border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      >M</div>
      <div className='pen-size-option'
        onClick={()=>{setDrawingOptions({...drawingOptions, size: 12})
        setChosenPalette((prev)=>({...prev,size: "L"}))}}
        style={{border: "6px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      >L</div>
      <div className='pen-size-option noClick'>COLOR</div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "red"})
        setChosenPalette((prev)=>({...prev,color: "red"}))}}
        style={{backgroundColor: "red",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option'
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "orange"})
        setChosenPalette((prev)=>({...prev,color: "orange"}))}}
        style={{backgroundColor: "orange",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "yellow"})
        setChosenPalette((prev)=>({...prev,color: "yellow"}))}}
        style={{backgroundColor: "yellow",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "green"})
        setChosenPalette((prev)=>({...prev,color: "green"}))}}
        style={{backgroundColor: "green",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option'
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "blue"})
        setChosenPalette((prev)=>({...prev,color: "blue"}))}}
        style={{backgroundColor: "blue",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "purple"})
        setChosenPalette((prev)=>({...prev,color: "purple"}))
      }}
        style={{backgroundColor: "purple",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
        
      ></div>
      <div className='pen-size-option' style={{ position: 'relative' }}>
        <input 
          type="color" 
          onChange={(e) => {
            const chosenColor = e.target.value;
            setDrawingOptions({ ...drawingOptions, color: chosenColor });
            setChosenPalette((prev) => ({ ...prev, color: chosenColor }));
          }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0, // This makes the input invisible but still clickable
            cursor: 'pointer'
          }}
        />
        <div 
          style={{
            background: drawingOptions.color,
            backgroundImage:'linear-gradient(90deg, red, orange, yellow, green, blue)',
            border: "4px solid white", 
            width: "1.6em", 
            height: "1.6em", 
            borderRadius: "50%"
          }}
        ></div>
</div>
    </div>
              )}
            {(chosenTool == "markupTool" && chosenToolOption != "trashMarkup" && chosenToolOption != "hideMarks" && chosenToolOption != "showMarks" && chosenToolOption ) && (
              <div className='options-bar palette noClick' >
                <div>
                  PALETTE
                </div>
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-evenly"}}>
                
                <div className='pen-size-option' 
                  style={{border: `2px solid white`, width: "2em", height: "2em", borderRadius: "50%"}}
                >{chosenPalette.size}</div>
                <div className='pen-size-option' 
                  style={{backgroundColor: `${chosenPalette.color}`,border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
                >
                  
                  </div>
                  <div className="options-button palette">
                  <div className='options-button-icon' style={{fontSize: "1.1em"}}>{chosenPalette.component}</div>
                </div>
                </div>
              </div>
            )}

          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption === "penMarkup" ? "" : "penMarkup")
            setChosenPalette((prev)=>({...prev,component: <BsFillPencilFill />}))
            }}>
            <div className='options-button-icon'><BsFillPencilFill /></div>
            <div className='options-button-text'>PEN TOOL</div>
          </div>
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption === "highlightMarkup" ? "" : "highlightMarkup")
            setChosenPalette((prev)=>({...prev,component: <FaHighlighter />}))
          }}
          >
            <div className='options-button-icon'><FaHighlighter /></div>
            <div className='options-button-text'>HIGHLIGHTER</div>
          </div>
          
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption === "arrowMarkup" ? "" : "arrowMarkup")
            setChosenPalette((prev)=>({...prev,component: <BsArrowUpRightSquareFill />}))
            }}>
            <div className='options-button-icon'><BsArrowUpRightSquareFill /></div>
            <div className='options-button-text'>ARROW TOOL</div>
          </div>


          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption === "rectangleMarkup" ? "" : "rectangleMarkup")
            setChosenPalette((prev)=>({...prev,component: <BsSquareHalf />}))
            }}>
            <div className='options-button-icon'><BsSquareHalf /></div>
            <div className='options-button-text'>RECTANGLE TOOL</div>
          </div>
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption === "circleMarkup" ? "" : "circleMarkup")
            setChosenPalette((prev)=>({...prev,component: <BsCircleHalf />}))
            }}>
            <div className='options-button-icon'><BsCircleHalf /></div>
            <div className='options-button-text'>CIRCLE TOOL</div>
          </div>

          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption === "trashMarkup" ? "" : "trashMarkup")
            setChosenPalette((prev)=>({...prev,component: <BsFillTrash3Fill />}))
            }}>
            <div className='options-button-icon'><BsFillTrash3Fill /></div>
            <div className='options-button-text'>DELETE MARK</div>
          </div>
          {markVis == true && (
            <div className="options-button-box" onClick={()=>{
              setChosenToolOption("hideMarks")
              setMarkVis(false)
              }}>
              <div className='options-button-icon'><BsEyeSlashFill /></div>
              <div className='options-button-text'>HIDE MARKS</div>
            </div>
          )}
          {markVis == false && (
            <div className="options-button-box" onClick={()=>{
              setChosenToolOption("showMarks")
              setMarkVis(true)
              }}>
              <div className='options-button-icon'><BsEyeFill /></div>
              <div className='options-button-text'>SHOW MARKS</div>
            </div>
          )}
        </div>
    )
  case "measureTool":
    return(
      <div style={{
        height: "30em"
      }}className="options-bar">

        {chosenToolOption == "scaleMeasure" &&  (
          <div className='options-bar mini scale'>
            <div className="options-button-box" style={{cursor:"default"}} >
            <div className=''>SCALE</div>
              <select
                ref={scaleRef}
                defaultValue={pageConstants.pageScale}
                style={{fontSize:"1.4em"}}
                onChange={(e)=>{
                  setPageConstants((prev)=>({...prev,pageScale:scaleRef.current.value}))
                  console.log(scaleRef.current.value)
                }}>
                  <option value={1}>1" = 1'</option>
                  <option value={2}>1/2" = 1'</option>
                  <option value={4}>1/4" = 1'</option>
                  <option value={8}>1/8" = 1'</option>
                  <option value={16}>1/16" = 1'</option>
              </select>
              
            </div>
          </div>
        )}
        {(chosenToolOption == "penMarkup" ||
              chosenToolOption == "highlightMarkup" ||
              chosenToolOption == "arrowMarkup" ||
              chosenToolOption == "rectangleMarkup" ||
              chosenToolOption == "circleMarkup"||
              chosenToolOption == "singleMeasure"||
              chosenToolOption == "drawLineMeasure"||
              chosenToolOption == "boxAreaMeasure"||
              chosenToolOption == "circleAreaMeasure"||
              chosenToolOption == "drawAreaMeasure") && (
            <div>
              <div className='options-bar mini'>
      <div className='pen-size-option noClick'>SIZE</div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, size: 4})
        setChosenPalette((prev)=>({...prev,size: "S"}))}}
        style={{border: "2px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      >S</div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, size: 8})
        setChosenPalette((prev)=>({...prev,size: "M"}))}}
        style={{border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      >M</div>
      <div className='pen-size-option'
        onClick={()=>{setDrawingOptions({...drawingOptions, size: 12})
        setChosenPalette((prev)=>({...prev,size: "L"}))}}
        style={{border: "6px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      >L</div>
      <div className='pen-size-option noClick'>COLOR</div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "red"})
        setChosenPalette((prev)=>({...prev,color: "red"}))}}
        style={{backgroundColor: "red",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option'
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "orange"})
        setChosenPalette((prev)=>({...prev,color: "orange"}))}}
        style={{backgroundColor: "orange",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "yellow"})
        setChosenPalette((prev)=>({...prev,color: "yellow"}))}}
        style={{backgroundColor: "yellow",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "green"})
        setChosenPalette((prev)=>({...prev,color: "green"}))}}
        style={{backgroundColor: "green",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option'
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "blue"})
        setChosenPalette((prev)=>({...prev,color: "blue"}))}}
        style={{backgroundColor: "blue",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
      ></div>
      <div className='pen-size-option' 
        onClick={()=>{setDrawingOptions({...drawingOptions, color: "purple"})
        setChosenPalette((prev)=>({...prev,color: "purple"}))
      }}
        style={{backgroundColor: "purple",border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
        
      ></div>
    <div className='pen-size-option' style={{ position: 'relative' }}>
        <input 
          type="color" 
          onChange={(e) => {
            const chosenColor = e.target.value;
            setDrawingOptions({ ...drawingOptions, color: chosenColor });
            setChosenPalette((prev) => ({ ...prev, color: chosenColor }));
          }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0, // This makes the input invisible but still clickable
            cursor: 'pointer'
          }}
        />
        <div 
          style={{
            background: drawingOptions.color,
            backgroundImage:'linear-gradient(90deg, red, orange, yellow, green, blue)',
            border: "4px solid white", 
            width: "1.6em", 
            height: "1.6em", 
            borderRadius: "50%"
          }}
        ></div>
      </div>
    </div>
              <div className='options-bar palette noClick' >
                <div>
                  PALETTE
                </div>
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-evenly"}}>
                
                <div className='pen-size-option' 
                  style={{border: `2px solid white`, width: "2em", height: "2em", borderRadius: "50%"}}
                >{chosenPalette.size}</div>
                <div className='pen-size-option' 
                  style={{backgroundColor: `${chosenPalette.color}`,border: "4px solid white", width: "2em", height: "2em", borderRadius: "50%"}}
                >
                  
                  </div>
                  <div className="options-button palette">
                  <div className='options-button-icon' style={{fontSize: "1.1em"}}>{chosenPalette.component}</div>
                </div>
                </div>
              </div>
            </div>
          
        )}
        
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption === "scaleMeasure" ? "" : "scaleMeasure")
            setChosenPalette((prev)=>({...prev,component: <RiCompasses2Fill />}))
            }}>
            <div className='options-button-icon'><RiCompasses2Fill /></div>
            <div className='options-button-text'>SET SCALE</div>
          </div>
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption=="singleMeasure" ? "" : "singleMeasure")
            setChosenPalette((prev)=>({...prev,component: <BsArrowLeftRight />}))
            }}>
            <div className='options-button-icon'><BsArrowLeftRight/></div>
            <div className='options-button-text'>LINE MEASURE</div>
          </div>
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption=="drawLineMeasure" ? "" : "drawLineMeasure")
            setChosenPalette((prev)=>({...prev,component: <img className='options-button-picture' src={tapeMeasure} />}))
            }}>
            <div className='options-button-icon'><img className='options-button-picture' src={tapeMeasure} /></div>
            <div className='options-button-text'>TAPE MEASURE</div>
          </div>
          
          
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption=="boxAreaMeasure" ? "" : "boxAreaMeasure")
            setChosenPalette((prev)=>({...prev,component: <BsGrid3X3 />}))
            }}>
            <div className='options-button-icon'><BsGrid3X3 /></div>
            <div className='options-button-text'>BOX AREA</div>
          </div>
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption=="drawAreaMeasure" ? "" : "drawAreaMeasure")
            setChosenPalette((prev)=>({...prev,component: <img className='options-button-picture' src={polyArea} />}))
            }}>
            <div className='options-button-icon'><img className='options-button-picture' src={polyArea2} /></div>
            <div className='options-button-text'>DRAW AREA</div>
          </div>
          <div className="options-button-box" onClick={()=>{
            setChosenToolOption(chosenToolOption=="trashMeasure" ? "" : "trashMeasure")
            setChosenPalette((prev)=>({...prev,component: <BsFillTrash3Fill />}))
            }}>
            <div className='options-button-icon'><BsFillTrash3Fill /></div>
            <div className='options-button-text'>TRASH MEASURE</div>
          </div>
          {measureVis == true && (
            <div className="options-button-box" onClick={()=>{
              setChosenToolOption("hideMeasure")
              setMeasureVis(false)
              }}>
              <div className='options-button-icon'><BsEyeSlashFill /></div>
              <div className='options-button-text'>HIDE MEASURES</div>
            </div>
          )}
          {measureVis == false && (
            <div className="options-button-box" onClick={()=>{
              setChosenToolOption("showMeasure")
              setMeasureVis(true)
              }}>
              <div className='options-button-icon'><BsEyeFill /></div>
              <div className='options-button-text'>SHOW MEASURES</div>
            </div>
          )}
          
        </div>
        
    )
  case "filesTool":
    return(
      <div style={{
        height: "12em"
      }}
        className="options-bar files"></div>
    )
  case "productsTool":
    return(
      <>
      {selectedStampIndexes.length > 0 && (
        <div
        style={{
          height:"5em",
          width:"8em"
        }} className="options-bar multiplier"
      >
        <div>
          MULTIPLIER:
        </div>
        <input ref={multiplyRef} onChange={()=>{multiplyStampsFunc()}} defaultValue={1} style={{width:"50%",fontSize:"1.6em"}} type="number"></input>
        <div style={{fontSize:"70%"}}>
          Multiply selected items
        </div>
      </div>
      )}
      
      {productCollapse == false && (
      <div style={{
        height: "30em",
        width:"22em"
      }}className="options-bar products">
        <div style={{color:"white"}}>
          <div style={{
                boxSizing:"border-box",
                display:"grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 2em",
                alignItems:"center",
                justifyContent:"space-evenly",
              }}>
                <div></div>
                <div>TITLE</div>
                <div>QTY</div>
                <div>SUM COST</div>
            </div>
            <div className={chosenToolOption==="lassoTool" ? "lasso-box-selected" : "lasso-box" }style={{
                borderBottom:"2px solid white",
                borderTop:"2px solid white",
                boxSizing:"border-box",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                cursor:"pointer",
              }}
              onClick={()=>{
                if (chosenToolOption==="lassoTool"){
                  setChosenProduct(null)
                  setChosenToolOption("")
                } else {
                  setChosenProduct(-1)
                  setChosenToolOption("lassoTool")
                }
              }} 
              >
                <div style={{width:"1.3em",height:"1.2em",fontSize:"1.5em",display:"flex",alignItems:"center",justifyContent:"center",border:"0px solid black",boxSizing:"border-box"}}>
                    <BiSelection/>

                </div>
                <div style={{width:"2.5em"}}></div>
                <div style={{width:"8em",fontSize:"1em"}}>Lasso Tool</div>
                <div style={{width:"3em",fontSize:"0.8em"}}></div>
              </div>
            <div style={{overflow:"auto", height:"20em"}}>
              
            
            {chosenItemsList.map((d,i)=>{
              return(<ProductTile setChosenToolOption={setChosenToolOption} forceUpdate={forceUpdate} productEditState={productEditState} setProductEditState={setProductEditState} countState={countState} deleteProductFromList={deleteProductFromList} removalState={removalState} iconRegistry={iconRegistry} productCollapse={productCollapse} chosenProduct={chosenProduct} setChosenProduct={setChosenProduct} d={d} i={i} key={i} />)
            })}
            </div>
            <div  style={{color:"white",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-evenly",bottom:"0",width:"100%",height:"5em",borderTop:"2px solid white"}}>
            <div className="options-button-box small" onClick={()=>{
              setProductAddingState(true)
              setChosenProduct(null)
              }}>
              <div className='options-button-icon'><BsPlusSquareFill /></div>
              <div className='options-button-text'>ADD PRODUCT</div>
              <div className='options-button-text'>TO LIST</div>
            </div>
            <div className="options-button-box small" onClick={()=>{
              if (chosenToolOption == "trashProductStamp"){setChosenToolOption("")}else{setChosenToolOption("trashProductStamp")}
              setChosenProduct(null)
              }}>
              <div className='options-button-icon'><BsFillTrash3Fill /></div>
              <div className='options-button-text'>DELETE PRODUCTS</div>
            </div>
            {productVis == true && (
              <div className="options-button-box" onClick={()=>{
                setProductVis(false)
                setChosenProduct(null)
                }}>
                <div className='options-button-icon'><BsEyeSlashFill /></div>
                <div className='options-button-text'>HIDE PRODUCTS</div>
              </div>
            )}
            {productVis == false && (
              <div className="options-button-box" onClick={()=>{
                setProductVis(true)
                }}>
                <div className='options-button-icon'><BsEyeFill /></div>
                <div className='options-button-text'>SHOW PRODUCTS</div>
              </div>
            )}
            
            <div className="options-button-box small" onClick={()=>{
              setProductCollapse(productCollapse==true ? false : true)
              }}>
              <div className='options-button-icon'><BsArrowLeftSquareFill /></div>
              <div className='options-button-text'>MINIMIZE</div>
              <div className='options-button-text'>WINDOW</div>
            </div>
            </div>
          </div>
        </div>
          
      )}
      {productCollapse == true && (
        <div style={{
          height: "30em",
          width:"4em"
        }}className="options-bar products">
        <div style={{color:"white"}}>
          <div style={{
                height:"1.2em"
              }}>
            </div>
            <div className='noScroll' style={{overflow:"auto", height:"26em",scrollbarWidth: "none", msOverflowStyle: "none"}}>

                <div style={{
                  boxSizing:"border-box",
                  display:"flex",
                  flexDirection:"column",
                  height: "2.2em",
                  alignItems:"center",
                  backgroundColor: chosenToolOption==="lassoTool" ? "white" : "transparent",
                  cursor:"pointer"
                }}
                onClick={()=>{
                  if (chosenToolOption==="lassoTool"){
                    setChosenProduct(null)
                    setChosenToolOption("")
                  } else {
                    setChosenProduct(-1)
                    setChosenToolOption("lassoTool")
                  }
                }} 
                >
                  <div style={{width:".3em"}}></div>
                  <div
                  className={chosenToolOption==="lassoTool" ? "lasso-box-selected mini" : "lasso-box mini"}
                  style={{
                  width:"1.4em",
                  height:"1.4em",
                  fontSize:"1.8em",
                  backgroundColor: chosenToolOption==="lassoTool" ? 'rgba(50, 50, 50,0.0)' : `rgba(50, 50, 50,0.5)`, // update color here
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center",
                  border: chosenToolOption==="lassoTool" ? '0px solid white' : "2px solid black", // update border color here
                  boxSizing:"border-box"}}>
                  <BiSelection />
                  </div>
                </div>
            {chosenItemsList.map((d,i)=>{
              return(<ProductTile setChosenToolOption={setChosenToolOption} forceUpdate={forceUpdate} chosenItemsList={chosenItemsList} productEditState={productEditState} setProductEditState={setProductEditState} iconRegistry={iconRegistry} productCollapse={productCollapse} chosenProduct={chosenProduct} setChosenProduct={setChosenProduct} d={d} i={i} key={i} />)
            })}
            </div>
            <div  style={{color:"white",display:"flex",flexDirection:"column",alignItems:"center",bottom:"0",width:"100%",height:"2em"}}>
            <div className="options-button-box tiny" onClick={()=>{
              setProductCollapse(productCollapse==true ? false : true)
              }}>
              <div className='options-button-icon'><BsArrowRightSquareFill /></div>
            </div>
            </div>
          </div>
          </div>
      )}
          
        
        </>
    )
  
}



  
}

export default OptionsBar