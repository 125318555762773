import NavBar from "./NavBar"
import './SplashStyles.css'
import architects from '../assets/architects.webp'
import planRoom from '../assets/plan-room.png'
import kitCad from '../assets/kit-cad.png'
import invoice from '../assets/invoice.png'
import holoBuilder from '../assets/holobuilder.png'
import {
  BsLightningChargeFill,
  BsFillFileEarmarkTextFill,
  BsFillPinMapFill,
  BsBoxSeamFill,
  BsHammer,
  BsCameraFill,
} from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import Footer from "./Footer"
const Catalog = () => {
  const navigate = useNavigate();

  return(
    <div className="App">
      <NavBar />
      <div className="catalog-hero-wrapper" >
        <div className="catalog-hero-container" >
        <div className="catalog-hero-description" >
            <h1 style={{color:"#ffde17"}}>Volt Software Catalog</h1>
            <div className="catalog-spacer"></div>
            <h3 style={{color:"#fff"}}>Our software applications, while each different, share many common core components.</h3>
            <div className="catalog-spacer"></div>
            <div className="catalog-hero-bullets">
              <p style={{color:"#fff"}}>• Created by construction workers, for construction workers. Volt's Founders all work in construction, and actually use Volt software in their day-to-day.</p>
              <div className="catalog-spacer"></div>
              <p style={{color:"#fff"}}>• Working together has never been easier. Collaborate with your entire team on the same blueprint, invoice, or kitchen in realtime.</p>
              <div className="catalog-spacer"></div>
              <p style={{color:"#fff"}}>• Safety first. We securely store your work using Google's cloud based storage. Never deal with a corrupted or missing file again.</p>
              <div className="catalog-spacer"></div>
              <p style={{color:"#fff"}}>• Built to be efficient. Each application is boiled down to its pure essentials. No extra bells & whistles here. Our application's user interfaces are as simple as we could possibly make them, without sacrificing any utility.</p>
            </div>
            <div className="catalog-spacer"></div>
            <div style={{display:'flex',flexDirection:'row', alignItems:"center", justifyContent:"space-evenly", width:"100%"}}>
              <BsFillPinMapFill style={{fontSize:"2em",color:"#00aeef"}} />
              <BsCameraFill style={{fontSize:"2em",color:"#ffde17"}} />
              <BsFillFileEarmarkTextFill style={{fontSize:"2em",color:"#ffde17"}} />
              <BsBoxSeamFill style={{fontSize:"2em",color:"#ffde17"}} />
            </div>
          </div>
          <div className="catalog-hero-img-wrapper">
            <img className="catalog-hero-img" src={architects} />
          </div>
        </div>
      </div>
      <div className='catalog-pane-wrapper' style={{backgroundColor:"#ffffff", color:"#fff"}} >
        <div onClick={()=>{navigate('/plan-room/app', { state: { myData: "Riverbend", isDemo: true } })}} className="catalog-pane-container" >
          <div className="catalog-img-wrapper">
            <img className="catalog-img" src={planRoom} />
            <div className="catalog-img-screen" />
            <div className="catalog-demo">&nbsp;&nbsp;&nbsp;<BsLightningChargeFill className="catalog-bolt" /> Try Demo</div>
          </div>
          
        </div>
        <div className="catalog-pane-description">
          <h1 className="catalog-title"><BsFillPinMapFill style={{fontSize:"1em",color:"#00aeef"}} /> Plan Room</h1> 
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Upload plans to our secure servers for Real-Time team collaboration. Use pins, bookmarks, and estimation markings to work together virtually.</p>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Simplify estimation with easy item pricing. Assign names and prices to various takeoff types, catering to all your pricing needs.</p>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Priced at 1/4th the cost of our competetors, Volt offers the lowest possible pricing for estimation software, without sacraficing on quality.</p>
          <div className="catalog-spacer"></div>
          <div className="catlog-button-wrapper">
            <div onClick={()=>{navigate('/plan-room/app', { state: { myData: "Riverbend", isDemo: true } })}} className='hero-button catalog' style={{backgroundColor:"#00aeef"}}><BsLightningChargeFill style={{color:"#ffde17"}} className='NavButtonIcon' />Try Demo</div>
            <div className='hero-button-big catalog' style={{backgroundColor:"#4CAF50", color:"white"}}>Buy now for only $50 / Month</div>
          </div>
        </div>

      </div>
      <div className='catalog-pane-wrapper' style={{backgroundColor:"#f7f7f7", color:"#fff"}} >
        <div className="catalog-pane-container" >
          <div className="catalog-img-wrapper">
            <img className="catalog-img" src={holoBuilder} />
            <div className="catalog-img-screen" />
            <div className="catalog-demo">&nbsp;<BsHammer className="catalog-bolt" /> Developing..</div>
          </div>
        </div>
        <div className="catalog-pane-description">
          <h1 className="catalog-title"><BsCameraFill style={{fontSize:"1em",color:"black"}} /> M.A.X 360</h1>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Experience construction sites like never before with our 360 Imaging Application. Capture comprehensive 360 images with a user-friendly camera and explore them in a Google Street View-like interface.</p>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Need to peek behind the drywall? Need to look and see if the plumbing was installed properly? Well now you can look back in time at your building through our 360 panoramic images to ensure your project is perfect start to finish.</p>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• M.A.X 360 will be available Summer 2024.</p>
          <div className="catalog-spacer"></div>
          <div className="catlog-button-wrapper">
            <div className='hero-button catalog' style={{backgroundColor:"gray"}}><BsHammer style={{color:"#ffde17"}} className='NavButtonIcon' />&nbsp; Under Construction</div>
            <div className='hero-button-big catalog' style={{backgroundColor:"#ffde17", color:"black"}}>Plans will start at $10,000</div>
          </div>
        </div>
      </div>

      <div className='catalog-pane-wrapper' style={{backgroundColor:"#ffffff", color:"#fff"}} >
        <div className="catalog-pane-container" >
          <div className="catalog-img-wrapper">
            <img className="catalog-img" src={invoice} />
            <div className="catalog-img-screen" />
            <div className="catalog-demo">&nbsp;<BsHammer className="catalog-bolt" /> Developing..</div>
          </div>
        </div>
        <div className="catalog-pane-description">
          <h1 className="catalog-title"><BsFillFileEarmarkTextFill style={{fontSize:"1em",color:"black"}} /> Invoicer</h1>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Manage your business documents with ease using our Invoicing System. Generate and store invoices seamlessly, keeping your business organized and efficient.</p>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• This app is free with no limits, to all users, forever. Our motto is to help build small business, so take this one is on us.</p>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Invoicer will be available Winter of 2024.</p>
          <div className="catalog-spacer"></div>
          <div className="catlog-button-wrapper">
          <div className='hero-button catalog' style={{backgroundColor:"gray"}}><BsHammer style={{color:"#ffde17"}} className='NavButtonIcon' />&nbsp; Under Construction</div>
            <div className='hero-button-big catalog' style={{backgroundColor:"#ffde17", color:"black"}}>This application will be Free!</div>
          </div>
        </div>
      </div>

      <div className='catalog-pane-wrapper' style={{backgroundColor:"#f7f7f7", color:"#fff"}} >
        <div className="catalog-pane-container" >
          <div className="catalog-img-wrapper">
            <img className="catalog-img" src={kitCad} />
            <div className="catalog-img-screen" />
            <div className="catalog-demo">&nbsp;<BsHammer className="catalog-bolt" /> Developing..</div>
          </div>
        </div>
        <div className="catalog-pane-description">
          <h1 className="catalog-title"><BsBoxSeamFill style={{fontSize:"1em",color:"black"}} /> Kit-Cad</h1>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Design kitchens effortlessly with our 3D Kitchen Design Application. Say goodbye to bloated and outdated software and embrace a simple, effective solution for drawing kitchen plans.</p>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Collaborate in realtime with your peers and design your kitchens together with no latency. Welcome to the future of kitchen design.</p>
          <div className="catalog-spacer"></div>
          <p className="catalog-text">• Kit-Cad will be available in 2025.</p>
          <div className="catalog-spacer"></div>
          <div className="catlog-button-wrapper">
          <div className='hero-button catalog' style={{backgroundColor:"gray"}}><BsHammer style={{color:"#ffde17"}} className='NavButtonIcon' />&nbsp; Under Construction</div>
            <div className='hero-button-big catalog' style={{backgroundColor:"#ffde17", color:"black"}}>Licenses will cost $50 / Month</div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Catalog