import {useState,useEffect} from 'react'

const LineMeasureMessage = ({
  chosenToolOption,
  viewBoxRef,
  isDrawingDimensionLine,
  currentDimensionLine,
  currentAreaDimension,
  isDrawingAreaDimension,
  pageConstants,
  currentLineDistance,
  isDrawingLinePath,
  currentLinePoints,
  framePos,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [imperialMeasure,setImperialMeasure] = useState({frac:[1,1]})
  const inverseScale = 1/ (framePos.scale * 2.5)

  useEffect(() => {
    const handleMouseMove = (e) => {
      const svg = viewBoxRef.current;
      const pt = svg.createSVGPoint();
      pt.x = e.clientX;
      pt.y = e.clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
      setPosition({ x: svgP.x, y: svgP.y });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  useEffect(()=>{
    if (currentDimensionLine) {
      let dist = getDistance(
        { x: currentDimensionLine.startX, y: currentDimensionLine.startY },
        { x: currentDimensionLine.endX, y: currentDimensionLine.endY }
      );
      let ft = (dist / 200 * pageConstants.pageScale)
      setImperialMeasure(convertToImperial(ft)) // <-- changed this from setDistance
    }
  },[currentDimensionLine])
useEffect(()=>{
  if (!currentDimensionLine) {
    setImperialMeasure({frac:[1,1]})
  } 
},[isDrawingDimensionLine])



useEffect(()=>{
  if (currentAreaDimension){
    // Calculate the area
    let width = Math.abs(currentAreaDimension.endX - currentAreaDimension.startX) / 200 * pageConstants.pageScale; // convert from px to ft
    let height = Math.abs(currentAreaDimension.endY - currentAreaDimension.startY) / 200 * pageConstants.pageScale; // convert from px to ft
    let sqft = width * height; // area in sq ft
    setImperialMeasure(convertToImperial(sqft))
  }
},[currentAreaDimension])
  
useEffect(()=>{
  console.log(currentLinePoints)
  if (currentLinePoints) {
    let dist = getDistance(
      { x: currentLinePoints.startX, y: currentLinePoints.startY },
      { x: currentLinePoints.endX, y: currentLinePoints.endY }
    );
    let currentDist = currentLineDistance
    let ft = ((dist + currentDist) / 200 * pageConstants.pageScale)
    setImperialMeasure(convertToImperial(ft))
  }
},[currentLineDistance,currentLinePoints])


  const getDistance = (point1, point2) => {
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    return Math.sqrt(dx * dx + dy * dy);
    
  }


  function convertToImperial(d) {
    // get the whole feet
    var feet = Math.floor(d);
    
    // get the remaining part in feet and convert to inches
    var decimalFeet = d - feet;
    var totalInches = decimalFeet * 12;
    
    // get the whole inches
    var inches = Math.floor(totalInches);
    
    // get the remaining part in inches and convert to sixteenths
    var decimalInches = totalInches - inches;
    var sixteenths = Math.round(decimalInches * 16);
    
    // if sixteenths is 16, it means we have a whole inch
    if (sixteenths === 16) {
      inches++;
      sixteenths = 0;
    }
    
    // if inches is 12, it means we have a whole foot
    if (inches === 12) {
      feet++;
      inches = 0;
    }
  
    // Simplify sixteenths fraction and convert to string
    let fracString = "";
    if (sixteenths % 2 === 0) {
      sixteenths /= 2;
      if (sixteenths % 2 === 0) {
        sixteenths /= 2;
      }
    }
    let fracArray = []
    if (sixteenths !== 0) {
      fracString = (sixteenths === 1) ? "1/16\"" : (sixteenths === 2) ? "1/8\"" : (sixteenths === 3) ? "3/16\"" : (sixteenths === 4) ? "1/4\"" : (sixteenths === 5) ? "5/16\"" : (sixteenths === 6) ? "3/8\"" : (sixteenths === 7) ? "7/16\"" : (sixteenths === 8) ? "1/2\"" : (sixteenths === 9) ? "9/16\"" : (sixteenths === 10) ? "5/8\"" : (sixteenths === 11) ? "11/16\"" : (sixteenths === 12) ? "3/4\"" : (sixteenths === 13) ? "13/16\"" : (sixteenths === 14) ? "7/8\"" : "15/16\"";
      fracArray = (sixteenths === 1) ? [1,16] : (sixteenths === 2) ? [1,8] : (sixteenths === 3) ? [3,16] : (sixteenths === 4) ? [1,4] : (sixteenths === 5) ? [5,16] : (sixteenths === 6) ? [3,8] : (sixteenths === 7) ? [7,16] : (sixteenths === 8) ? [1,2] : (sixteenths === 9) ? [9,16] : (sixteenths === 10) ? [5,8] : (sixteenths === 11) ? [11,16] : (sixteenths === 12) ? [3,4] : (sixteenths === 13) ? [13,16] : (sixteenths === 14) ? [7,8] : [15,16];
    }
  
    return {
      ft: feet,
      inch: inches,
      frac: fracArray,
    };
  }






  return (
    <svg>
      {(isDrawingLinePath == false && chosenToolOption == "drawLineMeasure") && (
        <svg>
          <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
          <rect
        x={80}
        y={80}
        width={310} 
        height={100} 
        rx={10} 
        ry={10} 
        fill="rgba(40, 40, 40, 0.95);" 
        stroke="black"
      />
      <text
        x={85}
        y={120}
        fill="white"
        fontSize="2.2em"
      >
        Click the first point.
      </text>
      <text
        x={85}
        y={160}
        fill="white"
        fontSize="2.2em"
      >
        Press Esc to cancel.
      </text>
      </g>
      </svg>
      )} 
      {isDrawingLinePath == true && (
        <svg>
          <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
          <rect
        x={80} 
        y={80} 
        width={330} 
        height={200} 
        rx={10} 
        ry={10} 
        fill="rgba(40, 40, 40, 0.95);" 
        stroke="black"
      />
      <text
        x={85}
        y={110}
        fill="white"
        fontSize="2.2em"
      >
       Click to add point.
      </text>
      <text
        x={85}
        y={260}
        fill="white"
        fontSize="2.2em"
      >
       Double click to end.
      </text>
      <foreignObject x={85} y={110} width="290" height="200">
      <div xmlns="http://www.w3.org/1999/xhtml" style={{color:"white", display: "flex", flexDirection: "row", alignItems: "center", fontSize:"3em",justifyContent:"space-between"}}>
        <div>{imperialMeasure.ft}'&nbsp;&nbsp;</div>
        <div>{imperialMeasure.inch}"</div>
        {imperialMeasure.frac.length == 0 && (
          <div style={{marginLeft: "5px", marginRight: "5px"}}>
            <div style={{borderBottom:"1px solid black"}}>0</div>
            <div>0</div>
          </div>
        )}
        {imperialMeasure.frac.length > 0 && (
          <div style={{marginLeft: "5px", marginRight: "5px"}}>
            <div style={{borderBottom:"1px solid black"}}>{imperialMeasure.frac[0]}</div>
            <div>{imperialMeasure.frac[1]}</div>
          </div>
        )}
 
      </div>
    </foreignObject>
    </g>
    </svg>
      )}
      {(isDrawingAreaDimension == false && chosenToolOption == "boxAreaMeasure") && (
        <svg>
          <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
          <rect
        x={80}
        y={80}
        width={310} 
        height={100} 
        rx={10} 
        ry={10} 
        fill="rgba(40, 40, 40, 0.95);" 
        stroke="black"
      />
      <text
        x={85}
        y={120}
        fill="white"
        fontSize="2.2em"
      >
        Click the first point.
      </text>
      <text
        x={85}
        y={160}
        fill="white"
        fontSize="2.2em"
      >
        Press Esc to cancel.
      </text>
      </g>
      </svg>
      )} 
      {isDrawingAreaDimension == true && (
        <svg>
          <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
          <rect
        x={80} 
        y={80} 
        width={330} 
        height={200} 
        rx={10} 
        ry={10} 
        fill="rgba(40, 40, 40, 0.95);" 
        stroke="black"
      />
      <text
        x={85}
        y={110}
        fill="white"
        fontSize="2.2em"
      >
       Double click to end.
      </text>
      <text
        x={85}
        y={260}
        fill="white"
        fontSize="2.2em"
      >
       Press Esc to cancel.
      </text>
      <foreignObject x={85} y={110} width="290" height="200">
      <div xmlns="http://www.w3.org/1999/xhtml" style={{color:"white", display: "flex", flexDirection: "row", alignItems: "center", fontSize:"3em",justifyContent:"space-between"}}>
        <div>{imperialMeasure.ft}'&nbsp;&nbsp;</div>
        <div>{imperialMeasure.inch}"</div>
        {imperialMeasure.frac.length == 0 && (
          <div style={{marginLeft: "5px", marginRight: "5px"}}>
            <div style={{borderBottom:"1px solid black"}}>0</div>
            <div>0</div>
          </div>
        )}
        {imperialMeasure.frac.length > 0 && (
          <div style={{marginLeft: "5px", marginRight: "5px"}}>
            <div style={{borderBottom:"1px solid black"}}>{imperialMeasure.frac[0]}</div>
            <div>{imperialMeasure.frac[1]}</div>
          </div>
        )}
 
      </div>
    </foreignObject>
      </g>
    </svg>
      )}
      {(isDrawingDimensionLine == false && chosenToolOption == "singleMeasure") && (
        <svg>
          <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
        <rect
        x={80}
        y={80}
        width={310} 
        height={100} 
        rx={10} 
        ry={10} 
        fill="rgba(40, 40, 40, 0.95);" 
        stroke="black"
      />
      <text
        x={85}
        y={120}
        fill="white"
        fontSize="2.2em"
      >
        Click the first point.
      </text>
      <text
        x={85}
        y={160}
        fill="white"
        fontSize="2.2em"
      >
        Press Esc to cancel.
      </text>
      </g>
      </svg>
      )} 
      {isDrawingDimensionLine == true && (
        <svg>
          <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
          <rect
        x={80} 
        y={80} 
        width={330} 
        height={200} 
        rx={10} 
        ry={10} 
        fill="rgba(40, 40, 40, 0.95);" 
        stroke="black"
      />
      <text
        x={85}
        y={110}
        fill="white"
        fontSize="2.2em"
      >
       Double click to end.
      </text>
      <text
        x={85}
        y={260}
        fill="white"
        fontSize="2.2em"
      >
       Press Esc to cancel.
      </text>
      <foreignObject x={85} y={110} width="290" height="200">
      <div xmlns="http://www.w3.org/1999/xhtml" style={{color:"white", display: "flex", flexDirection: "row", alignItems: "center", fontSize:"3em",justifyContent:"space-between"}}>
        <div>{imperialMeasure.ft}'&nbsp;&nbsp;</div>
        <div>{imperialMeasure.inch}"</div>
        {imperialMeasure.frac.length == 0 && (
          <div style={{marginLeft: "5px", marginRight: "5px"}}>
            <div style={{borderBottom:"1px solid black"}}>0</div>
            <div>0</div>
          </div>
        )}
        {imperialMeasure.frac.length > 0 && (
          <div style={{marginLeft: "5px", marginRight: "5px"}}>
            <div style={{borderBottom:"1px solid black"}}>{imperialMeasure.frac[0]}</div>
            <div>{imperialMeasure.frac[1]}</div>
          </div>
        )}
 
      </div>
    </foreignObject>
      </g>
    </svg>
      )}
      
    </svg>
  );

}

export default LineMeasureMessage