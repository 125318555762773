import {BsZoomIn, BsZoomOut} from 'react-icons/bs'
const ZoomButtons = ({currentScaleRef,framePos,setFramePos}) => {


const changeZoom = (button) => {
    // Calculate center of the viewport
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    // Calculate how much the cursor is offset from the center of the viewport
    const offsetX = centerX - framePos.x;
    const offsetY = centerY - framePos.y;

    // Adjust for the transform origin now being top-left
    const cursorPosXInFrame = (centerX - framePos.x) / framePos.scale;
    const cursorPosYInFrame = (centerY - framePos.y) / framePos.scale;

    let newScale = framePos.scale;

    if (button == "zoomIn") {
        newScale += 0.1;
    } else if (button == "zoomOut") {
        if (framePos.scale < 0.11) { return }
        newScale -= 0.1;
    }

    // Calculate the new position of the frame so the center stays at the same place in the frame
    const newFramePosX = centerX - cursorPosXInFrame * newScale;
    const newFramePosY = centerY - cursorPosYInFrame * newScale;

    setFramePos({
        scale: newScale,
        x: newFramePosX,
        y: newFramePosY
    });

    currentScaleRef.current = newScale;
  }


return(
  <div className="zoomBar">
    <div className="" onClick={()=>{changeZoom("zoomIn")}}>
      <div className="tool-icon zoom"><BsZoomIn/></div>
    </div>
    <div className="" onClick={()=>{changeZoom("zoomOut")}}>
      <div className="tool-icon zoom"><BsZoomOut/></div>
    </div>
  </div>
)
}

export default ZoomButtons