import { useState,useRef,useEffect } from "react"
import tinycolor from 'tinycolor2'
import Pin from "./Pin.js"
import ShadowPin from "./ShadowPin.js"
import ShadowMessage from "./ShadowMessage.js"
import DeleteMessage from "./DeleteMessage.js"
import MoveMessage from "./MoveMessage.js"
import Markup from "./Markup.js"
import Measure from "./Measure.js"
import LineMeasureMessage from "./LineMeasureMessage.js"

const SVGLayer = ({pageConstants, pageNum, chosenToolOption, pinList,
  setPinList, setEditingPinIndex, viewBoxRef, removePinFunc, marksList,
  setMarksList, drawingOptions, addMarkFunc, removeMarkFunc, handlePinSubmit,
  addMeasureFunc, measureList,removeMeasureFunc, forceMeasureUpdate,framePos,measureVis,
  shiftToggle,
}) => {

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// STATES & REFS & VARIABLES
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

////// GENERIC STATES //////
const [toolCursor,setToolCursor] = useState("default")

////// PIN STATES //////
const [addingPin,setAddingPin] = useState(false)
const [pinVis, setPinVis] = useState(true)

////// MARKUP STATES //////
const [markVis, setMarkVis] = useState(true)
//Pen
const [currentPenPath, setCurrentPenPath] = useState("")
const [isDrawing, setIsDrawing] = useState(false)
const [lastPoint, setLastPoint] = useState({x: 0, y: 0})
//Highlighter
const [currentHighlight, setCurrentHighlight] = useState(null)
const [initialX,setInitialX]=useState(null)
//Arrow
const [currentArrow, setCurrentArrow] = useState(null);
const [isDrawingArrow,setIsDrawingArrow] = useState(false)
//Shapes
const [currentRect,setCurrentRect] = useState(null);
const [currentCircle,setCurrentCircle] = useState(null);

////// MEASUREMENT STATES //////

//Single Line
const [currentDimensionLine, setCurrentDimensionLine] = useState(null);
const [isDrawingDimensionLine, setIsDrawingDimensionLine] = useState(false);
//Draw Line
const [currentLinePath,setCurrentLinePath] = useState([])
const [currentLineDistance,setCurrentLineDistance] = useState(0)
const [currentLinePoints,setCurrentLinePoints] = useState(null)
const [isDrawingLinePath,setIsDrawingLinePath] = useState(false)
//Box Area
const [currentAreaDimension, setCurrentAreaDimension] = useState(null);
const [isDrawingAreaDimension, setIsDrawingAreaDimension] = useState(false);
//Draw AREA
const [currentPolygonPath, setCurrentPolygonPath] = useState([]);
const [currentPolygonPoints, setCurrentPolygonPoints] = useState(null);
const [isDrawingPolygonPath, setIsDrawingPolygonPath] = useState(false);
const [currentTemporaryPolygonPath,setCurrentTemporaryPolygonPath] = useState([])

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// USE EFFECTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
useEffect(()=>{
  console.log(chosenToolOption)

  switch (chosenToolOption) {
  // PIN TOOLS //
    case "addPin": {
      setAddingPin(true)
      console.log("adding!")
      setToolCursor("crosshair")} break;
    case "editPin": {setAddingPin(false)} break;
    case "trashPin": {setAddingPin(false)} break;
    case "hidePin": {setPinVis(false)
                    setAddingPin(false)} break;
    case "showPin": {setPinVis(true)
                    setAddingPin(false)} break;
  // MARKUP TOOLS //
    case "penMarkup":{setToolCursor("crosshair")} break;
    case "highlightMarkup":{setToolCursor("text")} break;
    case "arrowMarkup":{setToolCursor("crosshair")} break;
    case "rectangleMarkup":{setToolCursor("crosshair")} break;
    case "circleMarkup":{setToolCursor("crosshair")} break;
    case "hideMarks": {setMarkVis(false)} break;
    case "showMarks": {setMarkVis(true)} break;
  // MEASUREMENT TOOLS //
    case "singleDimensionLine": {setToolCursor("crosshair")} break;
    case "areaDimension": {setToolCursor("crosshair");} break;
    default: {setAddingPin(false)
              setToolCursor("default")}}
},[chosenToolOption])

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// METHODS AND FUNCTIONS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
function convertToImperial(d) {
  // get the whole feet
  let feet = Math.floor(d);
  
  // get the remaining part in feet and convert to inches
  let decimalFeet = d - feet;
  let totalInches = decimalFeet * 12;
  
  // get the whole inches
  let inches = Math.floor(totalInches);
  
  // get the remaining part in inches and convert to sixteenths
  let decimalInches = totalInches - inches;
  let sixteenths = Math.round(decimalInches * 16);
  
  // if sixteenths is 16, it means we have a whole inch
  if (sixteenths === 16) {
    inches++;
    sixteenths = 0;
  }
  
  // if inches is 12, it means we have a whole foot
  if (inches === 12) {
    feet++;
    inches = 0;
  }

  // Simplify sixteenths fraction and convert to string
  let fracString = "";
  if (sixteenths % 2 === 0) {
    sixteenths /= 2;
    if (sixteenths % 2 === 0) {
      sixteenths /= 2;
    }
  }
  let fracArray = []
  if (sixteenths !== 0) {
    fracString = (sixteenths === 1) ? "1/16\"" : (sixteenths === 2) ? "1/8\"" : (sixteenths === 3) ? "3/16\"" : (sixteenths === 4) ? "1/4\"" : (sixteenths === 5) ? "5/16\"" : (sixteenths === 6) ? "3/8\"" : (sixteenths === 7) ? "7/16\"" : (sixteenths === 8) ? "1/2\"" : (sixteenths === 9) ? "9/16\"" : (sixteenths === 10) ? "5/8\"" : (sixteenths === 11) ? "11/16\"" : (sixteenths === 12) ? "3/4\"" : (sixteenths === 13) ? "13/16\"" : (sixteenths === 14) ? "7/8\"" : "15/16\"";
    fracArray = (sixteenths === 1) ? [1,16] : (sixteenths === 2) ? [1,8] : (sixteenths === 3) ? [3,16] : (sixteenths === 4) ? [1,4] : (sixteenths === 5) ? [5,16] : (sixteenths === 6) ? [3,8] : (sixteenths === 7) ? [7,16] : (sixteenths === 8) ? [1,2] : (sixteenths === 9) ? [9,16] : (sixteenths === 10) ? [5,8] : (sixteenths === 11) ? [11,16] : (sixteenths === 12) ? [3,4] : (sixteenths === 13) ? [13,16] : (sixteenths === 14) ? [7,8] : [15,16];
  }

  return {
    ft: feet,
    inch: inches,
    frac: fracArray,
  };
}


const calculatePolygonArea = (points) => {
  let area = 0;
  let j = points.length - 1;

  for (let i = 0; i < points.length; i++) {
    area += (points[j].x + points[i].x) * (points[j].y - points[i].y);
    j = i;
  }

  return Math.abs(area / 2);
};

const snapToAngle = (startX, startY, x, y) => {
  let endX = x;
  let endY = y;
  const dx = endX - startX;
  const dy = endY - startY;
  const angle = Math.atan2(dy, dx);
  const snappedAngle = Math.round(angle / (Math.PI / 8)) * (Math.PI / 8);
  const distance = Math.sqrt(dx * dx + dy * dy);
  endX = startX + Math.cos(snappedAngle) * distance;
  endY = startY + Math.sin(snappedAngle) * distance;
  return { x: endX, y: endY };
};

const addPin = (x,y,color) => {
  const newPin = {
    pageNum: pageNum,
    color: color,
    x: x,
    y: y,
    infoToggle: false,
    editState: true,
    pinTitle: "Untitled Pin",
    notes: [],
  }
  // setPinList([...pinList,newPin])
  handlePinSubmit(newPin)
}

const getDistance = (point1, point2) => {
  const dx = point2.x - point1.x;
  const dy = point2.y - point1.y;
  return Math.sqrt(dx * dx + dy * dy);
}
const getPathData = (coordinates) => {
  let pathData = `M ${coordinates[0].x} ${coordinates[0].y}`;
  for(let i=1; i<coordinates.length; i++){
      pathData += ` L ${coordinates[i].x} ${coordinates[i].y}`;
  }
  return pathData;
}
const segmentLengths = () => {
  if (currentLinePath.length === 0) return [];

  return currentLinePath.reduce((acc, segment, index) => {
    if (index === 0) return acc;
    const dx = segment.x - currentLinePath[index - 1].x;
    const dy = segment.y - currentLinePath[index - 1].y;
    return acc.concat(Math.sqrt(dx * dx + dy * dy));
  }, []);
};

const handleMouseDown = (e) => {
  if (e.button === 0) {  // Check for left click
      const svg = viewBoxRef.current;
      const pt = svg.createSVGPoint();
      pt.x = e.clientX;
      pt.y = e.clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
      if (addingPin == true) {
        addPin(svgP.x, svgP.y, "#ff0000");
        setAddingPin(false)
      } else if (chosenToolOption == "penMarkup") {
        setCurrentPenPath(`M${svgP.x},${svgP.y}`)
        setLastPoint({x: svgP.x, y: svgP.y}); // Here
        setIsDrawing(true)
      } else if (chosenToolOption == "highlightMarkup") {
        setInitialX(svgP.x)
        setCurrentHighlight({
          startX: svgP.x,
          startY: svgP.y - (drawingOptions.size * 10) /2 ,
          width: 0,
          height: (drawingOptions.size * 10), //HEIGHT CONSTANT OF HIGHLIGHT IS HERE
        })
      } else if (chosenToolOption == "arrowMarkup") {
        if (isDrawingArrow) {
          setCurrentArrow(prev => ({
            ...prev,
            endX: svgP.x,
            endY: svgP.y,
          }));
          setIsDrawingArrow(false);
        } else {
          setCurrentArrow({
            startX: svgP.x,
            startY: svgP.y,
            endX: svgP.x,
            endY: svgP.y,
          });
          setIsDrawingArrow(true);
        }
      } else if (chosenToolOption == "rectangleMarkup") {
        setCurrentRect({
          startX: svgP.x,
          startY: svgP.y,
          width: 0,
          height: 0,
        })
      } else if (chosenToolOption == "circleMarkup") {
        setCurrentCircle({
          centerX: svgP.x,
          centerY: svgP.y,
          radius: 0,
        })
      } else if (chosenToolOption == "singleMeasure") {
        if (!isDrawingDimensionLine) {
          setCurrentDimensionLine({
            startX: svgP.x,
            startY: svgP.y,
            endX: svgP.x,
            endY: svgP.y,
          });
          setIsDrawingDimensionLine(true);
        }
      } else if (chosenToolOption == "boxAreaMeasure") {
        if (!isDrawingAreaDimension) {
          setCurrentAreaDimension({
            startX: svgP.x,
            startY: svgP.y,
            endX: svgP.x,
            endY: svgP.y,
          });
          setIsDrawingAreaDimension(true);
        }
        
      } else if (chosenToolOption == "drawLineMeasure") {
        if(isDrawingLinePath) {
          let point = {
            x: svgP.x,
            y: svgP.y,
          }
          let lastPointPullout = currentLinePath[currentLinePath.length - 1]
          let distanceSegment = getDistance(lastPointPullout,point)
          setCurrentLinePath(prev=>([...prev,point]))
          setCurrentLineDistance(currentLineDistance + distanceSegment)
          setCurrentLinePoints({startX:svgP.x,startY:svgP.y,endX:svgP.x,endY:svgP.y})
        } else {
          setCurrentLinePath([{x:svgP.x,y:svgP.y}])
          setCurrentLinePoints({startX:svgP.x,startY:svgP.y,endX:svgP.x,endY:svgP.y})
          setIsDrawingLinePath(true);

        }
      }else if (chosenToolOption=="drawAreaMeasure"){
        console.log("drawing")
        if (isDrawingPolygonPath) {
          let point = { x: svgP.x, y: svgP.y };
          if (shiftToggle) {
            const lastPointPullout = currentPolygonPath[currentPolygonPath.length - 1];
            point = snapToAngle(lastPointPullout.x, lastPointPullout.y, svgP.x, svgP.y);
          }
          setCurrentPolygonPath(prev => ([...prev, point]));
          setCurrentPolygonPoints({ startX: point.x, startY: point.y, endX: point.x, endY: point.y });
        } else {
          setCurrentPolygonPath([{ x: svgP.x, y: svgP.y }]);
          setCurrentPolygonPoints({ startX: svgP.x, startY: svgP.y, endX: svgP.x, endY: svgP.y });
          setIsDrawingPolygonPath(true);
        }
      }
  }
};

const handleDoubleClick = (e) => {
  let newMeasure = {}
  if (chosenToolOption == "drawLineMeasure" && isDrawingLinePath) {
    let segLegs = segmentLengths()
    const firstPoint = currentLinePath[0]
    const lastPoint = currentLinePath[currentLinePath.length  -1]
    const drawOptionsCopy = {...drawingOptions}
  let newLineMeasure = {
    path: [...currentLinePath],
    startingPoint: firstPoint, 
    endingPoint: lastPoint,
    type: "drawLine",
    size: drawOptionsCopy.size,
    color: drawOptionsCopy.color,
    distance: currentLineDistance,
    length: segLegs.reduce((a, b) => a + b, 0),
  }
  if (Object.keys(newLineMeasure).length > 0) {
    addMeasureFunc(newLineMeasure)
  }
  setIsDrawingLinePath(false)
  setCurrentLinePath([])
  setCurrentLinePoints({})
  setCurrentLineDistance(0)
  }
  else if (chosenToolOption == "singleMeasure" && currentDimensionLine && isDrawingDimensionLine) {
    let dist = getDistance(
      { x: currentDimensionLine.startX, y: currentDimensionLine.startY },
      { x: currentDimensionLine.endX, y: currentDimensionLine.endY }
    );
    const newDimensionLine = {
      ...currentDimensionLine,
      type: "singleDimensionLine",
      distance: dist.toFixed(2),
      pageNum: pageNum,
      color: drawingOptions.color,
      size: drawingOptions.size,
    }
    newMeasure = newDimensionLine
    setCurrentDimensionLine(null)
    setIsDrawingDimensionLine(false)
  } else if (chosenToolOption == "boxAreaMeasure" && currentAreaDimension && isDrawingAreaDimension) {
    const area = Math.abs(currentAreaDimension.endX - currentAreaDimension.startX) * Math.abs(currentAreaDimension.endY - currentAreaDimension.startY);
    const newAreaDimension = {
      ...currentAreaDimension,
      type: "areaDimension",
      area: area.toFixed(5),
      pageNum: pageNum,
      color: drawingOptions.color,
      size: drawingOptions.size,
    };
    newMeasure = newAreaDimension;
    setCurrentAreaDimension(null);
    setIsDrawingAreaDimension(false);
  }else if (chosenToolOption == "drawAreaMeasure" && isDrawingPolygonPath) {
    // Calculating the area of the completed polygon
    const newPath = currentPolygonPath.slice(0, -1); // remove the last point
    const polygonArea = calculatePolygonArea(currentPolygonPath);
    const polygonAreaFT = polygonArea / (200 * 200) * pageConstants.pageScale * pageConstants.pageScale;

    const newPolygonStamp = {
      path: newPath,
      type: "polygon",
      areaFT: convertToImperial(polygonAreaFT), // Assuming a conversion function exists
      color: drawingOptions.color,
      size: drawingOptions.size,
      pageNum:pageNum,
      // Add any additional properties as required
    };
    newMeasure = newPolygonStamp
    
    setCurrentPolygonPath([]);
    setIsDrawingPolygonPath(false);
    setCurrentTemporaryPolygonPath([])
  }


  if (Object.keys(newMeasure).length > 0) {
    addMeasureFunc(newMeasure)
  }
  
}








const handleMouseMove = (e) => {
    const svg = viewBoxRef.current;
    const pt = svg.createSVGPoint();
    pt.x = e.clientX;
    pt.y = e.clientY;
    const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
    if (isDrawing && chosenToolOption === "penMarkup") {
      if (getDistance(lastPoint, svgP) > 20) { // Change 5 to whatever threshold you find smoothest
        setCurrentPenPath(prev => `${prev} L${Math.floor(svgP.x)},${Math.floor(svgP.y)}`);
        setLastPoint({x: Math.floor(svgP.x), y: Math.floor(svgP.y)}); // And here
      }
    } else if (chosenToolOption === "highlightMarkup" && currentHighlight) {
      setCurrentHighlight(prev => {
        const newWidth = svgP.x - initialX;
        if (newWidth < 0) {
          return {
            ...prev,
            startX: svgP.x,
            width: -newWidth,
          };
        } else {
          return {
            ...prev,
            startX: initialX,
            width: newWidth,
          };
        }
      });
    } else if (chosenToolOption == "arrowMarkup" && currentArrow && isDrawingArrow) {
      setCurrentArrow(prev => ({
        ...prev,
        endX: svgP.x,
        endY: svgP.y,
      }));
    } else if (chosenToolOption == "rectangleMarkup" && currentRect) {
      setCurrentRect(prev => ({
        ...prev,
        width: Math.floor(svgP.x - prev.startX),
        height: Math.floor(svgP.y - prev.startY),
      }));
    } else if (chosenToolOption == "circleMarkup" && currentCircle) {
      setCurrentCircle(prev => ({
        ...prev,
        radius: Math.floor(getDistance({x: svgP.x, y: svgP.y}, {x: prev.centerX, y: prev.centerY})),
      }));
    } else if (chosenToolOption == "singleMeasure" && currentDimensionLine && isDrawingDimensionLine) {
      setCurrentDimensionLine(prev => ({
        ...prev,
        endX: svgP.x,
        endY: svgP.y,
      }));
    } else if (chosenToolOption == "boxAreaMeasure" && currentAreaDimension && isDrawingAreaDimension) {
      setCurrentAreaDimension(prev => ({
        ...prev,
        endX: svgP.x,
        endY: svgP.y,
      }));
    } else if (currentLinePoints && isDrawingLinePath){
      // setCurrentLinePath(prev=>[...prev])
      setCurrentLinePoints(prev=>({...prev,endX:svgP.x,endY:svgP.y}))
      
    } else if (currentPolygonPoints && isDrawingPolygonPath) {
      const snappedEnd = shiftToggle ? snapToAngle(currentPolygonPoints.startX, currentPolygonPoints.startY, svgP.x, svgP.y) : svgP;
      setCurrentPolygonPoints(prev => ({ ...prev, endX: snappedEnd.x, endY: snappedEnd.y }));
      const temporaryPolygonPath = [...currentPolygonPath, { x: snappedEnd.x, y: snappedEnd.y }];
      setCurrentTemporaryPolygonPath(temporaryPolygonPath);
    }
};
const handleMouseUp = (e) => {
  let newMark = {}
  let newMeasure = {}
  if (isDrawing && chosenToolOption === "penMarkup") {
    const newPenPathCompiler = {
      type: "pen",
      path: currentPenPath,
      pageNum: pageNum,
      color: drawingOptions.color,
      size: drawingOptions.size
    }
    newMark = newPenPathCompiler
    // setMarksList([...marksList, newPenPathCompiler]);
    setCurrentPenPath("");
    setIsDrawing(false);
    // console.log(newPenPathCompiler.path)
  } else if (chosenToolOption === "highlightMarkup" && currentHighlight) {
    const newHighlight = {
      pageNum: pageNum,
      type: "highlight",
      x: currentHighlight.startX,
      y: currentHighlight.startY,
      width: currentHighlight.width,
      height: currentHighlight.height,
      color: drawingOptions.color,
      opacity: 0.4, // adjust the opacity here
    };
    newMark = newHighlight
    // setMarksList([...marksList, newHighlight]);
    setCurrentHighlight(null);
  } else if (chosenToolOption === "arrowMarkup" && currentArrow && !isDrawingArrow) {
    let angle = Math.atan2(currentArrow.endY - currentArrow.startY, currentArrow.endX - currentArrow.startX)
    const newArrow ={
      ...currentArrow,
      type: "arrow",
      angle: angle,
      pageNum: pageNum,
      color: drawingOptions.color,
      size: drawingOptions.size,
    }
    newMark = newArrow
    // setMarksList([...marksList,newArrow])
    setCurrentArrow(null)
  } else if (chosenToolOption == "rectangleMarkup" && currentRect) {
    const newRect = {
      pageNum: pageNum,
      type: "rectangle",
      x: currentRect.startX,
      y: currentRect.startY,
      width: currentRect.width,
      height: currentRect.height,
      size: drawingOptions.size,
      color: drawingOptions.color,
    };
    newMark = newRect
    // setMarksList([...marksList, newRect]);
    setCurrentRect(null);
  } else if (chosenToolOption == "circleMarkup" && currentCircle) {
    const newCircle = {
      pageNum: pageNum,
      type: "circle",
      cx: currentCircle.centerX,
      cy: currentCircle.centerY,
      r: currentCircle.radius,
      size: drawingOptions.size,
      color: drawingOptions.color,
    };
    newMark = newCircle
    // setMarksList([...marksList, newCircle]);
    setCurrentCircle(null);
  } 
  if (Object.keys(newMark).length > 0) {
    addMarkFunc(newMark)
  } 
};

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// XML RETURNS AND FUNCTIONAL COMPONENTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
return(
  <div className={`pin-layer ${chosenToolOption}`} style={{
    gridRowStart: 1,
    gridColumnStart: 1,
    zIndex: 10,
    cursor: `${toolCursor}`
  }}>
    <svg xmlns="http://www.w3.org/2000/svg" 
    viewBox={`0 0 ${pageConstants.stdImgSize.x} ${pageConstants.stdImgSize.y}`}
    onMouseDown={handleMouseDown}
    onMouseMove={handleMouseMove}
    onMouseUp={handleMouseUp}
    onDoubleClick={handleDoubleClick}
    ref={viewBoxRef}
    >
    <defs>
      {pinList.map((pin, index) => {
        const baseColor = tinycolor(pin.color);
        const alteredColor = baseColor.darken(20).toString();  // spin hue by 30 degrees and darken by 10%

        return (
          <linearGradient id={`grad${index}`} x1="0%" y1="0%" x2="100%" y2="0%" key={index}>
            <stop offset="40%" style={{stopColor: pin.color, stopOpacity:1}} />
            <stop offset="100%" style={{stopColor: alteredColor, stopOpacity:1}} />
          </linearGradient>
        );
      })}
    </defs>
    {currentPenPath && <path d={currentPenPath} strokeWidth={drawingOptions.size} stroke={drawingOptions.color} fill="transparent" />}
    {currentHighlight && 
      <rect 
        x={currentHighlight.startX} 
        y={currentHighlight.startY} 
        width={currentHighlight.width} 
        height={currentHighlight.height} 
        fill={drawingOptions.color} 
        opacity={drawingOptions.opacity || 0.5}
      />
    }
    {currentArrow && (
      <g>
        <line 
          x1={currentArrow.startX} 
          y1={currentArrow.startY}
          x2={currentArrow.endX} 
          y2={currentArrow.endY}
          stroke={drawingOptions.color}
          strokeWidth={drawingOptions.size}
        />
        {/* arrowhead */}
      </g>
    )}
    {currentRect && (
      <rect 
      x={currentRect.startX} 
      y={currentRect.startY} 
      width={currentRect.width} 
      height={currentRect.height} 
      stroke={drawingOptions.color} 
      fill="transparent"
      strokeWidth={(drawingOptions.size * 2)}
      />
    )}
    {currentCircle && (
      <circle 
      cx={currentCircle.centerX} 
      cy={currentCircle.centerY} 
      r={currentCircle.radius} 
      stroke={drawingOptions.color} 
      fill="transparent"
      strokeWidth={(drawingOptions.size * 2)}
      />
    )}
    {currentDimensionLine && (
      <g>
      {/* The line */}
      <line 
        x1={currentDimensionLine.startX} 
        y1={currentDimensionLine.startY}
        x2={currentDimensionLine.endX} 
        y2={currentDimensionLine.endY}
        stroke={drawingOptions.color}
        strokeWidth={drawingOptions.size}
        markerStart="url(#arrow)"
        markerEnd="url(#arrow)"
      />
    </g>
    )}
    {currentAreaDimension && (
      <rect 
      x={Math.min(currentAreaDimension.startX, currentAreaDimension.endX)}
      y={Math.min(currentAreaDimension.startY, currentAreaDimension.endY)}
      width={Math.abs(currentAreaDimension.startX - currentAreaDimension.endX)}
      height={Math.abs(currentAreaDimension.startY - currentAreaDimension.endY)}
      fill={drawingOptions.color}
      fillOpacity={0.3}
      />
    )}
    {currentLinePath.length > 0 && (
      <path 
          d={getPathData(currentLinePath)}
          stroke={drawingOptions.color}
          fill="none"
          strokeWidth={drawingOptions.size}
      />
    )}
    {currentLinePoints && (
      <line
      x1={currentLinePoints.startX}
      y1={currentLinePoints.startY}
      x2={currentLinePoints.endX}
      y2={currentLinePoints.endY}
      stroke={drawingOptions.color}
      strokeWidth={drawingOptions.size}
      />
    )}
    {(currentTemporaryPolygonPath.length > 1 && chosenToolOption=="drawAreaMeasure") && (
      <polygon
        points={currentTemporaryPolygonPath.map(p => `${p.x},${p.y}`).join(' ')}
        fill={drawingOptions.color}
        fillOpacity={0.3}
        stroke={drawingOptions.color}
        strokeWidth={10}
      />
    )}
    {/* Rendering the live preview line segment */}
    {(currentPolygonPoints && chosenToolOption=="drawAreaMeasure") && (
      <line
        x1={currentPolygonPoints.startX}
        y1={currentPolygonPoints.startY}
        x2={currentPolygonPoints.endX}
        y2={currentPolygonPoints.endY}
        stroke={drawingOptions.color}
        strokeWidth={10}
      />
    )}
    {marksList.toReversed().map((data,index)=>{
      if(markVis == false) {return} else {
        return(<Markup
          chosenToolOption={chosenToolOption}
          data={data} 
          index={index} 
          pageNum={pageNum}
          key={index}
          removeMarkFunc={removeMarkFunc}
          />)
      }
    })}
    {measureList.map((data,index)=>{
      if (measureVis == false) {return} else {
        return(<Measure
          pageNum={pageNum}
          chosenToolOption={chosenToolOption}
          removeMeasureFunc={removeMeasureFunc}
          data={data}
          index={index}
          key={index}
          pageConstants={pageConstants}
          forceMeasureUpdate={forceMeasureUpdate}
          />)
      }
    })}
    {pinList.map((pin,index)=>{
      if (pinVis === false) {return} else {
      return(<Pin 
        setEditingPinIndex={setEditingPinIndex}
        chosenToolOption={chosenToolOption} 
        pageNum={pageNum} 
        data={pin} 
        index={index} 
        key={index}
        removePinFunc={removePinFunc}
        viewBoxRef={viewBoxRef}
        handlePinSubmit={handlePinSubmit}
        />)
      }
    })}
     {addingPin && <ShadowPin viewBoxRef={viewBoxRef} />}
     {(chosenToolOption == "singleMeasure" || chosenToolOption == "boxAreaMeasure" || chosenToolOption == "drawLineMeasure") && 
     <LineMeasureMessage 
      currentLineDistance={currentLineDistance}
      isDrawingLinePath={isDrawingLinePath} 
      isDrawingAreaDimension={isDrawingAreaDimension} 
      currentAreaDimension={currentAreaDimension} 
      currentDimensionLine={currentDimensionLine} 
      viewBoxRef={viewBoxRef} 
      isDrawingDimensionLine={isDrawingDimensionLine} 
      pageConstants={pageConstants}
      chosenToolOption={chosenToolOption}
      currentLinePoints={currentLinePoints}
      framePos={framePos}
      />}
     {(chosenToolOption === "movePin") && <MoveMessage framePos={framePos} viewBoxRef={viewBoxRef} />}
     {(chosenToolOption === "editPin") && <ShadowMessage framePos={framePos} viewBoxRef={viewBoxRef} />}
     {(chosenToolOption === "trashPin" || chosenToolOption === "trashMarkup" || chosenToolOption==="trashMeasure") && <DeleteMessage framePos={framePos} viewBoxRef={viewBoxRef} chosenToolOption={chosenToolOption}/>}
    </svg>
  </div>
  )
}

export default SVGLayer