import './SplashStyles.css'
import {useState,useEffect,useRef} from 'react'
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc,getDoc } from "firebase/firestore";
import {useUserData} from '../Contexts/UserDataContext'
import {FBDataBase, FBAuth} from '../Firebase/firebase'
import LoadingOverlay from '../Utilities/LoadingOverlay';

const Login = () => {
  const navigate = useNavigate();

  const {setUID,setUserData,setUserCompanyData} = useUserData()
  const [signUpBool,setSignUpBool] = useState(false)
  const [loadingBool,setLoadingBool] = useState(false)
  const [businessBool,setBusinessBool] = useState(false)
  const [failureMessage,setFailureMessage] = useState()
  const [loadingText,setLoadingText] = useState()
  const loginEmailRef = useRef()
  const loginPasswordRef = useRef()
  const nameRef = useRef()
  const companyNameRef = useRef()
  const phoneNumberRef = useRef()
  const industryRef = useRef()
  const signupEmail = useRef()
  const signupPassword = useRef()
  const COnameRef = useRef()
  const COcompanyNameRef = useRef()
  const COphoneNumberRef = useRef()
  const COindustryRef = useRef()
  const COsignupEmail = useRef()
  const COsignupPassword = useRef()

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleLogin = () => {
    setLoadingBool(true)
    setLoadingText("Logging In")
    // console.log(loginEmailRef.current.value)
    // console.log(loginPasswordRef.current.value)
    try {
      signInWithEmailAndPassword(FBAuth,loginEmailRef.current.value,loginPasswordRef.current.value)
    .then((userCredentials)=>{
        // console.log(userCredentials)
			let uid = userCredentials.user.uid
      console.log("successful signin: ",uid)
			setUID(uid)
			if (uid) {
				const userDocRef = doc(FBDataBase, "users", uid);
				getDoc(userDocRef)
				  .then((docSnapshot) => {
					if (docSnapshot.exists()) {
					//   console.log("Setting user data:", docSnapshot.data());  // Debugging line
            const userData = docSnapshot.data()
					  setUserData(userData);
            if (userData.companyLinks.length > 0) {
              let companiesArray = []
              userData.companyLinks.map((docTitle,i)=>{
                const companyDocRef = doc(FBDataBase, "companies", docTitle);
				        getDoc(companyDocRef)
                .then((coSnapshot)=>{
                  if(coSnapshot.exists()){
                    const companyData = coSnapshot.data()
                    companiesArray.push(companyData)
                  }
                })
              })
              setUserCompanyData(companiesArray)
            } else {
              setUserCompanyData([])
            }
            navigate('/plan-room/')
					} else {
					  // console.error("No such document!");
            setFailureMessage("ERROR: Reload app and try again.")
					}
				  })
				  .catch((error) => {
					// console.error("Error getting doc", error);
          setLoadingBool(false)
          setFailureMessage("ERROR: Reload app and try again.")
				  });
			  }
        })
        .catch((error)=>{
            // console.error(error)
            setLoadingBool(false)
            setFailureMessage("Wrong email / password.")
        })
    } catch (err) {
      setLoadingBool(false)
      console.error("whole thing failed!",err)
    }
  }

  const handleSignup = async() => {
    // console.log(nameRef.current.value)
    // console.log(companyNameRef.current.value)
    // console.log(phoneNumberRef.current.value)
    // console.log(industryRef.current.value)
    // console.log(signupEmail.current.value)
    // console.log(signupPassword.current.value)
    const functionURL = "https://adduser-p5tpqkzc3q-uc.a.run.app"
    try {
      setLoadingBool(true)
      setLoadingText("Signing You Up")
      let postPackage = {
        email:signupEmail.current.value,
        userName:nameRef.current.value,
        password: signupPassword.current.value,
        companyName:companyNameRef.current.value,
        phoneNumber:phoneNumberRef.current.value,
        industry:industryRef.current.value,
      }
      const response = await fetch(functionURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postPackage),
        });
        if (!response.ok) {
          setLoadingBool(false)
          throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const uidResponse = await response.text();
          console.log("USER UID: ",uidResponse)
          await delay(1000)
          signInWithEmailAndPassword(FBAuth,signupEmail.current.value,signupPassword.current.value)
          .then(()=>{
              // console.log(userCredentials)
              setUID(uidResponse)
              if (uidResponse) {
                  const userDocRef = doc(FBDataBase, "users", uidResponse);
                  getDoc(userDocRef)
                  .then((docSnapshot) => {
                  if (docSnapshot.exists()) {
                  //   console.log("Setting user data:", docSnapshot.data());  // Debugging line
                      setUserData(docSnapshot.data());
                  } else {
                      console.error("No such user documents document!");
                      setLoadingBool(false)
                  }
                  })
                  .catch((error) => {
                  console.error("Error getting user document", error);
                  setLoadingBool(false)
                  });
                  setLoadingBool(false)
                  console.log('SIGN UP SUCCESS')
                  navigate('/plan-room/')
                  } else {
                      setLoadingBool(false)
                  }
          })
          .catch((error)=>{
              console.error(error)
              setLoadingBool(false)
          })
    } catch(err) {
      console.error(err)
      setLoadingBool(false)
    }
    
  }

  const handleCompanySignup = async() => {
    const functionURL = "https://adduserandcompany-p5tpqkzc3q-uc.a.run.app"
    try {
      setLoadingBool(true)
      setLoadingText("Creating Company Account")
      let postPackage = {
        email:COsignupEmail.current.value,
        userName:COnameRef.current.value,
        password: COsignupPassword.current.value,
        companyName:COcompanyNameRef.current.value,
        phoneNumber:COphoneNumberRef.current.value,
        industry:COindustryRef.current.value,
      }
      const response = await fetch(functionURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postPackage),
        });
        if (!response.ok) {
          setLoadingBool(false)
          throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const uidResponse = await response.text();
          console.log("USER UID: ",uidResponse)
          await delay(1000)
          signInWithEmailAndPassword(FBAuth,COsignupEmail.current.value,COsignupPassword.current.value)
          .then(()=>{
              // console.log(userCredentials)
              setUID(uidResponse)

              if (uidResponse) {
                  const userDocRef = doc(FBDataBase, "users", uidResponse);
                  getDoc(userDocRef)
                  .then((docSnapshot) => {
                  if (docSnapshot.exists()) {
                  //   console.log("Setting user data:", docSnapshot.data());  // Debugging line
                      const userData = docSnapshot.data()
                      setUserData(userData);
                      if (userData.companyLinks.length > 0) {
                        let companiesArray = []
                        userData.companyLinks.map((docTitle,i)=>{
                          const companyDocRef = doc(FBDataBase, "companies", docTitle);
                          getDoc(companyDocRef)
                          .then((coSnapshot)=>{
                            if(coSnapshot.exists()){
                              const companyData = coSnapshot.data()
                              companiesArray.push(companyData)
                            }
                          })
                        })
                        setUserCompanyData(companiesArray)
                      } else {
                        setUserCompanyData([])
                      }
                      navigate('/plan-room/')
                  } else {
                      console.error("No such user documents document!");
                      setLoadingBool(false)
                  }
                  })
                  .catch((error) => {
                  console.error("Error getting user document", error);
                  setLoadingBool(false)
                  });
                  setLoadingBool(false)
                  console.log('SIGN UP SUCCESS')
                  
                  } else {
                      setLoadingBool(false)
                  }
          })
          .catch((error)=>{
              console.error(error)
              setLoadingBool(false)
          })
    } catch(err) {
      console.error(err)
      setLoadingBool(false)
    }
  }

  return(
    <div className='loginPage'>
      {(signUpBool==false&&businessBool==false)&&(
        <>
        <div className="loginHeader">
      
        </div>
        <div className="loginBox">
          <div className='loginTitle'>
            Log Into Volt
          </div>
          <div style={{height:"1.5em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Email:
            </div>
            <input ref={loginEmailRef} type="email" placeholder='your@email.example' className='loginInput'></input>
          </div>
          <div style={{height:"2.5em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Password:
            </div>
            <input ref={loginPasswordRef} type="password" className='loginInput'></input>
          </div>
          <div style={{height:"2.5em"}} />
          <div className='loginFooter'>
            <div onClick={handleLogin} className='loginButton blue'>
              <div className='loginButtonText'>LOG IN</div>
            </div>
            <div style={{height:"2em"}} />
            <div onClick={()=>setSignUpBool(true)} className='loginButton gray'>
             <div  className='loginButtonText'>CREATE PERSONAL ACCOUNT</div>
            </div>
            <div style={{height:"0.5em"}} />
            <div onClick={()=>setBusinessBool(true)} className='loginButton gray'>
             <div  className='loginButtonText'>CREATE COMPANY ACCOUNT</div>
            </div>
          </div>
        </div>
        </>
      )}
      {signUpBool==true&&(
        <>
        <div className="loginHeader">
      
        </div>
        <div className="loginBox large">
          <div className='loginTitle'>
            Create Personal VOLT Account
          </div>
          <div style={{fontSize:"1em"}}>
            Recommended for almost all users. Work solo or join a company to collaborate.
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Your name:
            </div>
            <input ref={nameRef} type="text" placeholder='John Doe' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Company name:
            </div>
            <input ref={companyNameRef} type="text" placeholder='Construction Corp.' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Phone number:
            </div>
            <input ref={phoneNumberRef} type="tel" placeholder='000-000-000' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Industry:
            </div>
            <input ref={industryRef} type="text" placeholder='Framing / Plumbing / ..' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Primary email:
            </div>
            <input ref={signupEmail} type="email" placeholder='your@email.example' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Set password:
            </div>
            <input ref={signupPassword} type="password" className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginFooter'>
            <div onClick={handleSignup} className='loginButton blue'>
              <div className='loginButtonText'>CREATE PERSONAL ACCOUNT</div>
            </div>
            <div style={{height:"1em"}} />
            <div onClick={()=>setSignUpBool(false)} className='loginButton gray'>
             <div  className='loginButtonText'>CANCEL</div>
            </div>
          </div>
        </div>
        </>
      )}
      {businessBool==true&&(
        <>
        <div className="loginHeader">
      
        </div>
        <div className="loginBox large">
          <div className='loginTitle'>
            Create Company Volt Account
          </div>
          <div style={{fontSize:"1em"}}>
            Recommended for the leader of the team. Create an admin account for your company where you can invite Personal accounts to collaborate.
          </div>
          <div style={{height:"1em"}} />
          
          <div className='loginSite'>
            <div className='loginString'>
              Company name:
            </div>
            <input ref={COcompanyNameRef} type="text" placeholder='Construction Corp.' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Your name:
            </div>
            <input ref={COnameRef} type="text" placeholder='John Doe' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Phone number:
            </div>
            <input ref={COphoneNumberRef} type="tel" placeholder='000-000-000' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Industry:
            </div>
            <input ref={COindustryRef} type="text" placeholder='Framing / Plumbing / ..' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Primary email: (used for login)
            </div>
            <input ref={COsignupEmail} type="email" placeholder='your@email.example' className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginSite'>
            <div className='loginString'>
              Set password: (used for login)
            </div>
            <input ref={COsignupPassword} type="password" className='loginInput'></input>
          </div>
          <div style={{height:"1em"}} />
          <div className='loginFooter'>
            <div onClick={handleCompanySignup} className='loginButton blue'>
              <div className='loginButtonText'>CREATE COMPANY ACCOUNT</div>
            </div>
            <div style={{height:"1em"}} />
            <div onClick={()=>setBusinessBool(false)} className='loginButton gray'>
             <div  className='loginButtonText'>CANCEL</div>
            </div>
          </div>
        </div>
        </>
      )}
      {loadingBool&&(
        <LoadingOverlay text={loadingText} />
      )}
    </div>
  )
}

export default Login