import {useState,useEffect,useRef} from 'react'
import React from 'react'
import {BsGrid3X3,BsHourglass} from 'react-icons/bs'
import {TbRulerMeasure} from 'react-icons/tb'
import {LuStamp} from 'react-icons/lu'
import polyArea2 from './poly-area2.svg'

const MouseMessageBoard = ({d,framePos,eviewBoxRef,chosenItemsList,iconRegistry}) => {
  
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [stampTitle,setStampTitle] = useState("")
  const [footageQuantity,setFootageQuantity] = useState(0)
  const [measurementType,setMeasurementType] = useState("")
  const [boxSizes,setBoxSizes]=useState({x:0,y:0})
  const [stampTypeIcon,setStampTypeIcon] = useState(<BsHourglass />)
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
      const handleMouseMove = (e) => {
        const svg = eviewBoxRef.current;
        const pt = svg.createSVGPoint();
        pt.x = e.clientX;
        pt.y = e.clientY;
        const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
        setPosition({ x: svgP.x, y: svgP.y });
      };
      window.addEventListener('mousemove', handleMouseMove);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
  }, []);

  useEffect(()=>{
    if(d){
      // console.log(d)
      // setIsVisible(true)
    chosenItemsList.map((dat,i)=>{
      if (dat.id == d.matchingId) {
        setStampTitle(dat.title)
        setStampTypeIcon(iconRegistry[dat.icon])
      }
    })
    switch(d.type){
      case "stamp": {
        setFootageQuantity(0)
        setMeasurementType("")
        setBoxSizes({y:100,x:400})
        // setStampTypeIcon(<LuStamp/>)
      }
      break;
      case "line":{
        setFootageQuantity(d.distanceFT)
        setMeasurementType("LF")
        setBoxSizes({y:140,x:400})
        // setStampTypeIcon(<TbRulerMeasure/>)
      }
      break;
      case "areaBox":{
        setFootageQuantity(d.areaFT)
        setMeasurementType("SQFT")
        setBoxSizes({y:140,x:400})
        // setStampTypeIcon(<BsGrid3X3/>)
      }
      break;
      case "polygon":{
        setFootageQuantity(d.areaFT)
        setMeasurementType("SQFT")
        setBoxSizes({y:140,x:400})
        // setStampTypeIcon(<img style={{height:"1em"}} src={polyArea2} />)
      }
      break;
      default:{
        setFootageQuantity(0)
        setMeasurementType("")
      }
    }}
  },[d])



  const inverseScale = 1/ (framePos.scale * 2.5)
  
if (d) {
  return(
    <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`} >
    <rect
      width={85} 
      height={100} 
      rx={10}
      y={-100} 
      fill="rgba(40, 40, 40, 0.95);" 
      stroke="black"
    />
    <g transform={`translate(${10}, ${-80})`}>
      {React.cloneElement(stampTypeIcon,{fill:`${d.color}`,fontSize:"4em"})}
    </g>
    <rect
      width={boxSizes.x} 
      height={boxSizes.y}
      rx={10}
      fill="rgba(40, 40, 40, 0.95);" 
      stroke="black"
    />
    <text y={40} fill="white" fontSize="2.2em">• {stampTitle}</text>
    <text y={80} fill="white" fontSize="2.2em">• Multiplier: {d.multiplier}</text>
    {(!measurementType==""&&!footageQuantity==0) && (
      <text y={120} fill="white" fontSize="2.2em">• {measurementType}: {footageQuantity.ft}</text>
    )}
    
  </g>
  )
}
}

export default MouseMessageBoard