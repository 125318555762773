import { useNavigate } from "react-router-dom"
import { useEffect } from "react";
const QrCard = () => {
  const navigate = useNavigate();
	useEffect(()=> {
    navigate("/")
  })


}

export default QrCard