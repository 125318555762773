import { Routes, Route, BrowserRouter } from 'react-router-dom';
import JobTilesPage from './Plan-Room-App/Dashboard/JobTilesPage';
import Splash from './Splash-Page/Splash';
import { UserDataProvider } from './Contexts/UserDataContext';
import PlanRoomApp from './Plan-Room-App/App'
import Catalog from './Splash-Page/Catalog';
import About from './Splash-Page/About';
import QrCard from './Splash-Page/QrCard';
import Login from './Splash-Page/Login';
import Support from './Splash-Page/Support'
import ARLPrivacy from './Splash-Page/ARLPrivacy'


const RouteApp = () => {
return(
<BrowserRouter>
  <UserDataProvider>
    <Routes>
      <Route path="/" element={<Splash />} />
      <Route path="/catalog" element={<Catalog />} />
      <Route path="/about" element={<About />} />
      <Route path="/arlsupport" element={<Support />} />
      <Route path="/login" element={<Login />} />
      <Route path="/qrcard" element={<QrCard />} />
      <Route path="/demo" element={<PlanRoomApp />} />

      <Route path="/plan-room" element={<JobTilesPage />} />
      <Route path="/plan-room/app" element={<PlanRoomApp />} />

      
      <Route path="/arlsupport" element={<Support />} />
      <Route path="/arlprivacy" element={<ARLPrivacy/>} />
    </Routes>
  </UserDataProvider>
</BrowserRouter>
)
}

export default RouteApp