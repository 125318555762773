import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, onSnapshot } from "firebase/firestore"; // Import onSnapshot
import { FBDataBase } from '../Firebase/firebase';

const UserDataContext = createContext({});

export const useUserData = () => {
  return useContext(UserDataContext)
}

export const UserDataProvider = ({children})=>{
  const [UID,setUID] = useState()
  const [userData,setUserData] = useState()
  const [userCompanyData,setUserCompanyData] = useState()

  return(
    <UserDataContext.Provider value={{UID,userData,userCompanyData,setUID,setUserData,setUserCompanyData}}>
      {children}
    </UserDataContext.Provider>
  )
}