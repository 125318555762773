import { useState,useEffect } from "react";

const LassoMessage = ({framePos, eviewBoxRef}) => {

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const inverseScale = 1/ (framePos.scale * 2.5)

  useEffect(() => {
    const handleMouseMove = (e) => {
      const svg = eviewBoxRef.current;
      const pt = svg.createSVGPoint();
      pt.x = e.clientX;
      pt.y = e.clientY;
      const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
      setPosition({ x: svgP.x, y: svgP.y });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <g transform={`translate(${position.x + 60}, ${position.y + 70}) scale(${inverseScale})`}>
    <rect
      width={340} 
      height={140} 
      rx={10} 
      ry={10} 
      fill="rgba(40, 40, 40, 0.95);" 
      stroke="black"
    />
    <text y={40} fill="white" fontSize="2.2em">Click and drag.</text>
    <text y={80} fill="white" fontSize="2.2em">Press ESC to cancel.</text>
    <text y={120} fill="white" fontSize="2.2em">Press DEL to delete.</text>
  </g>
  );
};

export default LassoMessage