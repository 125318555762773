import {useState,useEffect} from 'react'

const Measure = ({data, index, pageConstants,chosenToolOption,removeMeasureFunc,pageNum}) => {

  const [boxScale, setBoxScale] = useState()
  const [fontScale, setFontScale] = useState()
  const [hoverToggle,setHoverToggle] = useState(false)
  
  const [imperialMeasure,setImperialMeasure] = useState({frac:[1,1]})


  useEffect(()=>{
    setBoxScale(data.size/1.25)
    setFontScale(data.size/2)
    if (data.type == "singleDimensionLine" || data.type == "drawLine") {
    let distance = data.distance
    let ft = (distance / 200 * pageConstants.pageScale)
    setImperialMeasure(convertToImperial(ft))
    } else if (data.type === "areaDimension") {
      // Calculate the area
      let width = Math.abs(data.endX - data.startX) / 200 * pageConstants.pageScale; // convert from px to ft
      let height = Math.abs(data.endY - data.startY) / 200 * pageConstants.pageScale; // convert from px to ft
      let sqft = width * height; // area in sq ft
    
      // Convert to imperial
      setImperialMeasure(convertToImperial(sqft));
    }

  },[pageConstants, data])

  const getPathData = (coordinates) => {
    let pathData = `M ${coordinates[0].x} ${coordinates[0].y}`;
    for(let i=1; i<coordinates.length; i++){
        pathData += ` L ${coordinates[i].x} ${coordinates[i].y}`;
    }
    return pathData;
  }

  function convertToImperial(d) {
    // get the whole feet
    let feet = Math.floor(d);
    
    // get the remaining part in feet and convert to inches
    let decimalFeet = d - feet;
    let totalInches = decimalFeet * 12;
    
    // get the whole inches
    let inches = Math.floor(totalInches);
    
    // get the remaining part in inches and convert to sixteenths
    let decimalInches = totalInches - inches;
    let sixteenths = Math.round(decimalInches * 16);
    
    // if sixteenths is 16, it means we have a whole inch
    if (sixteenths === 16) {
      inches++;
      sixteenths = 0;
    }
    
    // if inches is 12, it means we have a whole foot
    if (inches === 12) {
      feet++;
      inches = 0;
    }
  
    // Simplify sixteenths fraction and convert to string
    let fracString = "";
    if (sixteenths % 2 === 0) {
      sixteenths /= 2;
      if (sixteenths % 2 === 0) {
        sixteenths /= 2;
      }
    }
    let fracArray = []
    if (sixteenths !== 0) {
      fracString = (sixteenths === 1) ? "1/16\"" : (sixteenths === 2) ? "1/8\"" : (sixteenths === 3) ? "3/16\"" : (sixteenths === 4) ? "1/4\"" : (sixteenths === 5) ? "5/16\"" : (sixteenths === 6) ? "3/8\"" : (sixteenths === 7) ? "7/16\"" : (sixteenths === 8) ? "1/2\"" : (sixteenths === 9) ? "9/16\"" : (sixteenths === 10) ? "5/8\"" : (sixteenths === 11) ? "11/16\"" : (sixteenths === 12) ? "3/4\"" : (sixteenths === 13) ? "13/16\"" : (sixteenths === 14) ? "7/8\"" : "15/16\"";
      fracArray = (sixteenths === 1) ? [1,16] : (sixteenths === 2) ? [1,8] : (sixteenths === 3) ? [3,16] : (sixteenths === 4) ? [1,4] : (sixteenths === 5) ? [5,16] : (sixteenths === 6) ? [3,8] : (sixteenths === 7) ? [7,16] : (sixteenths === 8) ? [1,2] : (sixteenths === 9) ? [9,16] : (sixteenths === 10) ? [5,8] : (sixteenths === 11) ? [11,16] : (sixteenths === 12) ? [3,4] : (sixteenths === 13) ? [13,16] : (sixteenths === 14) ? [7,8] : [15,16];
    }
  
    return {
      ft: feet,
      inch: inches,
      frac: fracArray,
    };
  }

  const calculateBoundingBoxCenter = (points) => {
    let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
    points.forEach(point => {
      minX = Math.min(minX, point.x);
      maxX = Math.max(maxX, point.x);
      minY = Math.min(minY, point.y);
      maxY = Math.max(maxY, point.y);
    });
    return {
      x: (minX + maxX) / 2,
      y: (minY + maxY) / 2,
    };
  };

const dx = data.endX - data.startX;
const dy = data.endY - data.startY;
const magnitude = Math.sqrt(dx * dx + dy * dy);
const dirX = dx / magnitude;
const dirY = dy / magnitude;

const arrowSize = 10;
const newStartX = data.startX + dirX * arrowSize;
const newStartY = data.startY + dirY * arrowSize;
const newEndX = data.endX - dirX * arrowSize;
const newEndY = data.endY - dirY * arrowSize;

let boxWidthEm = 3 * boxScale; // in em
let boxHeightEm = 16 * boxScale; // in px
// Assume 1em is roughly 16px
let boxWidthPx = boxWidthEm * 16; // in px
  


if (data && pageNum == data.pageNum) {
  switch(data.type){
    case "singleDimensionLine": return(
      <g className={`measures ${hoverToggle}`} 
      onMouseEnter={() => { if (chosenToolOption == "trashMeasure") {setHoverToggle(true)}} }
      onMouseLeave={() => { setHoverToggle(false)} }
      onClick={()=>{if (chosenToolOption == "trashMeasure"){removeMeasureFunc(data,index)}}}>
      {/* The line */}
      <line 
        x1={newStartX} 
        y1={newStartY}
        x2={newEndX} 
        y2={newEndY}
        stroke={data.color}
        strokeWidth={data.size * 1}
        markerStart={`url(#startArrow${index})`}
        markerEnd={`url(#endArrow${index})`}
      />
  
      {/* Arrowheads */}
      <defs>
        <marker id={`startArrow${index}`} markerWidth="10" markerHeight="10" refX="1.5" refY="3" orient="auto" markerUnits="strokeWidth">
          <path d="M0,3 L9,0 L9,6 z" fill={data.color} />
        </marker>
        <marker id={`endArrow${index}`} markerWidth="10" markerHeight="10" refX="7" refY="3" orient="auto" markerUnits="strokeWidth">
          <path d="M0,0 L0,6 L9,3 z" fill={data.color} />
        </marker>
      </defs>

      <rect 
        x={data.startX + ((data.endX - data.startX) / 2) - boxWidthPx / 2} 
        y={data.startY + ((data.endY - data.startY) / 2) - boxHeightEm / 2} 
        width={`${boxWidthEm}em`} 
        height={`${boxHeightEm}px`}
        fill="white"
        stroke={data.color}
      />

      {/* The label */}
      <text
        x={data.startX + ((data.endX - data.startX) / 2)} 
        y={data.startY + ((data.endY - data.startY + (data.size*7)) / 2)} 
        fontSize={`${fontScale}em`}
        textAnchor="middle"
        alignmentBaseline="middle"
        style={{fill: 'black'}}
      >
        <tspan>{imperialMeasure.ft}' </tspan>
          <tspan>{imperialMeasure.inch}</tspan>
        <tspan>"</tspan>
      </text>
    </g>
    )
    case "areaDimension": return(
      <g className={`measures ${hoverToggle}`}
      onMouseEnter={() => { if (chosenToolOption == "trashMeasure") {setHoverToggle(true)}} }
      onMouseLeave={() => { setHoverToggle(false)} }
      onClick={()=>{if (chosenToolOption == "trashMeasure"){removeMeasureFunc(data,index)}}}>
        {/* The rectangle */}
        <rect 
          x={Math.min(data.startX, data.endX)}
          y={Math.min(data.startY, data.endY)}
          width={Math.abs(data.startX - data.endX)}
          height={Math.abs(data.startY - data.endY)}
          fill={data.color}
          fillOpacity={0.5}
          stroke="black"
          strokeWidth="3"
          strokeOpacity={1.0}
        />

        {/* The label */}
        <rect 
          x={data.startX + ((data.endX - data.startX) / 2) - boxWidthPx / 2} 
          y={data.startY + ((data.endY - data.startY) / 2) - boxHeightEm / 2} 
          width={`${boxWidthEm}em`} 
          height={`${boxHeightEm}px`}
          fill="white"
          stroke={data.color}
        />

        {/* The label */}
        <text
          x={data.startX + ((data.endX - data.startX) / 2)} 
          y={data.startY + ((data.endY - data.startY + (data.size*7)) / 2)} 
          fontSize={`${fontScale}em`}
          textAnchor="middle"
          alignmentBaseline="middle"
          style={{fill: 'black'}}
        >{imperialMeasure.ft} FT&#178;
        </text>
      </g>
    )
    case "drawLine": {
      return(
        <g 
          onClick={()=>{if (chosenToolOption == "trashMeasure"){removeMeasureFunc(data,index)}}}
          onMouseEnter={() => { if (chosenToolOption == "trashMeasure") {setHoverToggle(true)}} }
          onMouseLeave={() => { setHoverToggle(false)} }
          className={`measures ${hoverToggle}`}>
          <circle 
            cx={data.startingPoint.x} 
            cy={data.startingPoint.y} 
            r={data.size * 2} 
            stroke={data.color} 
            fill={data.color}
          />
          <circle 
            cx={data.startingPoint.x} 
            cy={data.startingPoint.y} 
            r={data.size * 4} 
            stroke={data.color} 
            fill="transparent"
            strokeWidth={data.size * 1}
          />
          <path 
              d={getPathData(data.path)}
              stroke={data.color}
              fill="none"
              strokeWidth={data.size}
          />
          <circle 
            cx={data.endingPoint.x} 
            cy={data.endingPoint.y} 
            r={data.size * 2} 
            stroke={data.color} 
            fill={data.color}
          />
          <circle 
            cx={data.endingPoint.x} 
            cy={data.endingPoint.y} 
            r={data.size * 4} 
            stroke={data.color} 
            fill="transparent"
            strokeWidth={data.size * 1}
          />
          <rect 
            x={data.startingPoint.x + (boxWidthEm / 2)} 
            y={data.startingPoint.y - (data.size*7)} 
            width={`${boxWidthEm}em`} 
            height={`${boxHeightEm}px`}
            fill="white"
            stroke={data.color}
          />

          {/* The label */}
          <text
            x={data.startingPoint.x + boxWidthEm*8} 
            y={data.startingPoint.y  - (data.size-fontScale*7)} 
            fontSize={`${fontScale}em`}
            textAnchor="middle"
            alignmentBaseline="middle"
            style={{fill: 'black'}}
          >
            <tspan>{imperialMeasure.ft}' </tspan>
              <tspan>{imperialMeasure.inch}</tspan>
            <tspan>"</tspan>
          </text>
        </g>
    )}
    case "polygon":
      if (data) {
        const center = calculateBoundingBoxCenter(data.path);
      return(
        <g className={`measures ${hoverToggle}`}
          onMouseEnter={() => { if (chosenToolOption === "trashMeasure") { setHoverToggle(true) } }}
          onMouseLeave={() => { setHoverToggle(false) }}
          onClick={() => { if (chosenToolOption === "trashMeasure") { removeMeasureFunc(data, index) } }}>
          <polygon
            points={data.path.map(p => `${p.x},${p.y}`).join(' ')}
            fill={data.color}
            fillOpacity={0.5}
            stroke="black"
            strokeWidth="3"
            strokeOpacity={1.0}
          />

          {/* The label */}
          <rect
            x={center.x - (boxWidthEm * 16) / 2} // Adjust 8 with your desired scaling factor
            y={center.y - boxHeightEm / 2}
            width={`${boxWidthEm}em`}
            height={`${boxHeightEm}px`}
            fill="white"
            stroke={data.color}
          />

          {/* The text */}
          <text
            x={center.x}
            y={center.y}
            fontSize={`${fontScale}em`}
            textAnchor="middle"
            alignmentBaseline="middle"
            style={{ fill: 'black' }}
          >{data.areaFT.ft} FT&#178;
          </text>
        </g>
      )
      }
  }
}
}
export default Measure