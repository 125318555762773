import {useState,useEffect,useRef} from 'react'
const Markup = ({data, index, pageNum, chosenToolOption,removeMarkFunc}) => {

const [hoverToggle, setHoverToggle] = useState(false)

if (data.pageNum == pageNum) {
switch (data.type) {
  case "pen": return(
    <path
    onMouseEnter={() => { if (chosenToolOption == "trashMarkup") {setHoverToggle(true)}} }
    onMouseLeave={() => { setHoverToggle(false)} }
    onClick={()=>{if (chosenToolOption == "trashMarkup"){removeMarkFunc(data,index)}}}
    className={`marks ${hoverToggle}`}
    d={data.path} 
    strokeWidth={data.size} 
    stroke={data.color} 
    fill="transparent" 
    key={index}
    />
  );
  case "highlight": return(
    <rect
    onMouseEnter={() => { if (chosenToolOption == "trashMarkup") {setHoverToggle(true)}} }
    onMouseLeave={() => { setHoverToggle(false)} }
    onClick={()=>{if (chosenToolOption == "trashMarkup"){removeMarkFunc(data,index)}}}
    className={`marks ${hoverToggle}`}
    x={data.x} 
    y={data.y} 
    width={data.width} 
    height={data.height} 
    fill={data.color} 
    opacity={0.4}
    />
  );
  case "arrow":
    let arrowHeadLength = 5 * data.size
    let dx = arrowHeadLength * Math.cos(data.angle);
    let dy = arrowHeadLength * Math.sin(data.angle);
    let p1 = `${data.endX - dx - dy / 2},${data.endY - dy + dx / 2}`;
    let p2 = `${data.endX - dx + dy / 2},${data.endY - dy - dx / 2}`;

    let shortenLength = data.size; // Half the stroke width
    let shortenDx = shortenLength * Math.cos(data.angle);
    let shortenDy = shortenLength * Math.sin(data.angle);

    return(
      <g key={index}
      onMouseEnter={() => { if (chosenToolOption == "trashMarkup") {setHoverToggle(true)}} }
      onMouseLeave={() => { setHoverToggle(false)} }
      onClick={()=>{if (chosenToolOption == "trashMarkup"){removeMarkFunc(data,index)}}}
      className={`marks ${hoverToggle}`}
      >
      <line 
      x1={data.startX} 
      y1={data.startY}
      x2={data.endX - shortenDx} 
      y2={data.endY - shortenDy}
      stroke={data.color}
      strokeWidth={data.size}
      />
      {/* arrowhead */}
      <polygon points={`${data.endX},${data.endY} ${p1} ${p2}`} fill={data.color}/>
    </g>
    );
  case "rectangle":{ return(
    <rect 
    onMouseEnter={() => { if (chosenToolOption == "trashMarkup") {setHoverToggle(true)}} }
    onMouseLeave={() => { setHoverToggle(false)} }
    onClick={()=>{if (chosenToolOption == "trashMarkup"){removeMarkFunc(data,index)}}}
    className={`marks ${hoverToggle}`}
    x={data.x} 
    y={data.y} 
    width={data.width} 
    height={data.height} 
    stroke={data.color} 
    fill="transparent"
    strokeWidth={data.size * 4}
    />
  )};
  case "circle":{return(
    <circle 
    onMouseEnter={() => { if (chosenToolOption == "trashMarkup") {setHoverToggle(true)}} }
    onMouseLeave={() => { setHoverToggle(false)} }
    onClick={()=>{if (chosenToolOption == "trashMarkup"){removeMarkFunc(data,index)}}}
    className={`marks ${hoverToggle}`}
    cx={data.cx} 
    cy={data.cy} 
    r={data.r} 
    stroke={data.color} 
    fill="transparent"
    strokeWidth={data.size * 4}
    />
  )}
  default: return
}
}
}
export default Markup