import {useState,useEffect,useRef} from 'react'

const PinListUI = ({pinList,teleportToPin}) => {
//STATES
//AND
//REFS


//EFFECTS


//METHODS
//AND
//FUNCTIONS


//XML
//RETURNS
  const PinDataRow = ({pin,index}) => {
    return(
      <div className='pin-list-ui-row' onClick={()=>teleportToPin(pin)}>
        <div className='color-frame'>
            <div style={{backgroundColor:`${pin.color}`}} className='color-picker noClick'></div>
          </div>
        <div className='pin-list-ui-cell' style={{width: '12%'}}>
          #{index}
        </div>
        <div className='pin-list-ui-cell' style={{width: '20%'}}>
          Page: {pin.pageNum}
        </div>
        <div className='pin-list-ui-cell'>
          {pin.pinTitle}
        </div>
        
      </div>
    )
  }






  return(
    <div style={{
      position: 'fixed',
      top: 0, bottom: 0, left: 0, right: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <div style={{
        backgroundColor: '#2d2d2d',
        padding: '1em',
        width: "70%",
        height: "80%",
        display: "flex",
        flexDirection: "column",
        fontSize: "1.5em",
        alignItems: "center",
        borderRadius: "1em",
        filter: `drop-shadow(0px 0px 10px black)`,
        color: "white",
      }}><h3>Click on a pin to quickly navigate to its location.</h3>
        <div style={{overflowX: "hidden",
        overflowY: "scroll",
        width: "100%",
        height: "100%",
        border: "2px solid black",
        fontSize: "1em",
        }}>
          {pinList.map((pin,index) => {
            return(<PinDataRow pin={pin} index={index} key={index} />)
          })}
        </div>
      </div>
    </div>
  )
}

export default PinListUI
