import {useState, useRef, useEffect} from 'react'
import {BsPlusSquareFill,BsXCircleFill} from 'react-icons/bs'

const PageSidebar = ({
  pageAddingState,
  setPageAddingState,
  favoritePages,
  setFavoritePages,
  setPageNum,
  pageNumRef,
  delBookMarkFromFirebase
}) => {

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// STATES & REFERENCES
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const [removalState, setRemovalState] = useState(false)


///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// METHODS AND FUNCTIONS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const onRowClick = (page,index) => {
  if (removalState == false) {
    setPageNum(page.pageNum)
    pageNumRef.current.value = page.pageNum
  }
  else if (removalState == true) {
    delBookMarkFromFirebase(page.id)
  }
}


///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////// XML RETURNS AND FUNCTIONAL COMPONENTS
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
const FavPageRow = ({page,index}) => {
  return(
    <div className='fav-page-row' onClick={()=>onRowClick(page,index)}>
      <div style={{width: "25%"}}>Page: {page.pageNum} </div>
      <div style={{width: "75%"}}>{page.name}</div>
    </div>
  )
}

return (
  <div className='fav-page-wrapper'>
    <div style={{marginTop: "4em"}}></div>
    <h3>Choose a page & navigate to it.</h3>
    <div className='fav-page-list'>
    {favoritePages.map((page,index)=>{
      return(<FavPageRow page={page} index={index} key={index}/>)
    })}
    </div>
    <div className='fav-page-buttons-wrapper'>
      <div className='fave-page-button' onClick={() => setPageAddingState(pageAddingState == true ? false : true)}>
        <div style={{fontSize: "2em"}}><BsPlusSquareFill /></div>
        <div style={{fontSize: "1.1em"}}>ADD</div>
      </div>
      {removalState == false && (
        <div className='fave-page-button' onClick={()=>{setRemovalState(removalState == true ? false : true)}}>
        <div style={{fontSize: "2em"}}><BsXCircleFill /></div>
        <div style={{fontSize: "1.1em"}}>REMOVE</div>
        </div>
      )}
      {removalState == true && (
        <div className='fave-page-button' onClick={()=>{setRemovalState(removalState == true ? false : true)}}>
        <div style={{fontSize: "2em", color: "red"}}><BsXCircleFill /></div>
        <div style={{fontSize: "1em"}}>CLICK TO REMOVE</div>
        </div>
      )}
    </div>
  </div>
)
}

export default PageSidebar